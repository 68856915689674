<template>
  <div v-if="$store.state.videoChat.active">
    <v-navigation-drawer
      fixed
      right
      permanent
      width="200px"
      :expand-on-hover="!$store.state.device.hasTouch"
      color="rgba(255, 255, 255, 0.95)"
      :mini-variant="drawerClosed"
    >
      <v-list v-if="user.uid">
        <v-list-item>
          <v-row>
            <v-col
              class="user-column"
              :style="{
                'margin-left': (drawerClosed ? -8 : 10) + 'px',
              }"
            >
              <profile-photo :photo-u-r-l="user.photoURL" :round="true" />
            </v-col>
            <v-col v-if="!drawerClosed" cols="8">
              {{ user.displayName }}
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>

      <v-list v-else>
        <v-list-item @click="openLoginDialog()">
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logg inn</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list nav dense>
        <v-list-item @click="endCall()">
          <v-list-item-icon>
            <v-icon color="red darken-3">call_end</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="red darken-3">Avslutt samtale</v-list-item-title>
        </v-list-item>

        <v-tooltip v-if="!$store.state.selectedInputDevices.video" left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon disabled>videocam_off</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="text--disabled">
                  Kamera på
                </span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>
            Finner ikke kamera
          </span>
        </v-tooltip>

        <v-list-item v-if="$store.state.selectedInputDevices.video" @click="toggleVideo()">
          <v-list-item-icon v-if="cameraActive">
            <v-icon color="blue darken-3">videocam</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="cameraActive">
            Kamera av
          </v-list-item-title>
          <v-list-item-icon v-if="!cameraActive">
            <v-icon color="red darken-3">videocam_off</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="!cameraActive">
            Kamera på
          </v-list-item-title>
        </v-list-item>

        <v-tooltip v-if="!$store.state.selectedInputDevices.audio" left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon disabled>mic_off</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="text--disabled">
                  Mikrofon på
                </span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>
            Finner ikke mikrofon
          </span>
        </v-tooltip>

        <v-list-item v-if="$store.state.selectedInputDevices.audio" @click="toggleAudio()">
          <v-list-item-icon v-if="audioActive">
            <v-icon color="blue darken-3">mic</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="audioActive">
            Mikrofon av
          </v-list-item-title>
          <v-list-item-icon v-if="!audioActive">
            <v-icon color="red darken-3">mic_off</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="!audioActive">
            Mikrofon på
          </v-list-item-title>
        </v-list-item>

        <v-tooltip v-if="!$store.state.selectedInputDevices.video" left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon disabled>fullscreen</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="text--disabled">
                  Fullskjerm
                </span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>
            Finner ikke kamera
          </span>
        </v-tooltip>

        <v-list-item @click="enableFullscreen()" v-if="$store.state.selectedInputDevices.video">
          <v-list-item-icon>
            <v-icon color="blue darken-3">fullscreen</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Fullskjerm</v-list-item-title>
        </v-list-item>

        <v-tooltip v-if="isSafari" left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon :disabled="true">
                  screen_share
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title v-if="!isMobile">
                <span class="text--disabled">
                  Del skjerm
                </span>
              </v-list-item-title>
              <v-list-item-title v-else>
                <span class="text--disabled">
                  Ikke tilgjengelig
                </span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>
            Støttes ikke av Safari
          </span>
        </v-tooltip>

        <v-list-item v-else :disabled="screenSharingInProgress || isMobile || screenShareStartup" @click="toggleScreenSharing()">
          <v-list-item-icon v-if="!screenSharingActive">
            <v-icon :disabled="screenSharingInProgress || isMobile" color="blue darken-3">screen_share</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="!screenSharingActive && !isMobile">
            Del skjerm
          </v-list-item-title>
          <v-list-item-icon v-else-if="screenSharingActive">
            <v-icon color="red darken-3">stop_screen_share</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="screenSharingActive">
            Avslutt skjermdeling
          </v-list-item-title>
        </v-list-item>

        <v-list-item :disabled="!user.uid || recordingStarting" @click="toggleRecording()">
          <v-list-item-icon v-if="!recordingActive">
            <v-icon :disabled="recordingStarting" color="blue darken-3">lens</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="!user.uid">
            Logg inn for å starte opptak
          </v-list-item-title>
          <v-list-item-title v-else-if="recordingStarting">
            Starter opptak
          </v-list-item-title>
          <v-list-item-title v-else-if="!recordingActive">
            Start opptak
          </v-list-item-title>
          <v-list-item-icon v-if="recordingActive">
            <v-icon color="red darken-3">lens</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="recordingActive">
            Stopp opptak
          </v-list-item-title>
        </v-list-item>

        <v-list-item :disabled="!user.uid" @click="openUserDrawer()">
          <v-list-item-icon>
            <v-icon>video_library</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="!user.uid">
            Logg inn for å se tidligere opptak
          </v-list-item-title>
          <v-list-item-title v-else>
            Se tidligere opptak
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="reconnect()"
          v-if="!$store.state.selectedInputDevices.video || !$store.state.selectedInputDevices.audio"
        >
          <v-list-item-icon>
            <v-icon>video_call</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Koble til på nytt</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-if="user.uid">
        <v-list-item link @click="$signOut">
          <v-list-item-icon>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Logg ut
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-btn
      v-if="$store.state.device.hasTouch"
      fab
      small
      depressed
      dark
      color="#009bff"
      :style="{
        right: toggleButtonPosition,
      }"
      class="drawer-toggle-button"
      @click="toggleDrawer()"
    >
      <v-icon v-if="drawerClosed">
        keyboard_arrow_left
      </v-icon>
      <v-icon v-else>
        keyboard_arrow_right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import ProfilePhoto from './ProfilePhoto';

export default {
  components: {
    ProfilePhoto,
  },
  props: [
    'cameraActive',
    'audioActive',
    'recordingActive',
    'screenSharingActive',
    'screenSharingInProgress',
    'recordingStarting',
    'screenShareStartup',
  ],
  data() {
    return {
      drawerClosed: true,
      screenShareAvailable: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isSafari() {
      return this.$store.state.device.isSafari;
    },
    isMobile() {
      return this.$store.state.device.isMobile;
    },
    toggleButtonPosition() {
      const basePosition = this.drawerClosed ? 35 : 180;

      if (this.$vuetify.breakpoint.smAndUp) {
        return `${basePosition}px`;
      }

      return `${basePosition - 56}px`;
    },
  },
  methods: {
    ...mapMutations(['setLoginDialogVisibility', 'setUserDrawerActive']),
    openLoginDialog() {
      this.setLoginDialogVisibility(true);
    },
    endCall() {
      this.$emit('endCall');
    },
    toggleVideo() {
      this.$emit('toggleVideo');
    },
    toggleAudio() {
      this.$emit('toggleAudio');
    },
    enableFullscreen() {
      this.$emit('enableFullscreen');
    },
    openUserDrawer() {
      this.setUserDrawerActive(true);
    },
    toggleDrawer() {
      this.drawerClosed = !this.drawerClosed;
    },
    toggleRecording() {
      this.$emit('toggleRecording');
    },
    toggleScreenSharing() {
      this.$emit('toggleScreenSharing');
    },

    reconnect() {
      this.$emit('attemptReconnecting');
    },
  },
};
</script>

<style lang="stylus" scoped>
.drawer-toggle-button
  z-index 20
  position fixed
  bottom: 50vh
  transition-property right

.user-column
  max-width 43px
  padding 0
</style>
