<template>
  <div>
    <div
      id="js-video-wrapper"
      class="video-wrapper"
    >
      <div
        id="js-local-stream"
        class="local-stream"
      />
    </div>

    <div>
      <right-drawer
        :cameraActive="cameraActive"
        :audioActive="audioActive"
        :recordingActive="recordingActive"
        :recordingStarting="recordingStarting"
        :screenSharingActive="localScreenSharingActive"
        :screenSharingInProgress="screenSharingInProgress"
        :screenShareStartup="screenShareStartup"
        @endCall="handleEndCall"
        @toggleVideo="handleToggleVideo"
        @toggleAudio="handleToggleAudio"
        @toggleRecording="handleToggleRecording"
        @toggleScreenSharing="handleToggleScreenSharing"
        @enableFullscreen="enableFullscreen"
        @attemptReconnecting="initLocalStreamWithDefaultDevices"
      />
    </div>

    <v-btn
      class="back-button"
      fab
      @click="switchToWhiteBoard"
      color="white"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-icon>
        gesture
      </v-icon>
    </v-btn>

    <user-device-and-browser-info
      :show-user-info="showUserInfo"
      :selected-user-device-and-browser-info="selectedUserDeviceAndBrowserInfo"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';
import screenfull from 'screenfull';
import random from 'lodash/random';
import AgoraRTC from 'agora-rtc-sdk';

import axios from 'axios';
import * as agoraService from '../services/agora';
import RightDrawer from './RightDrawer';
import credentials from '../config/credentials';
import firebase from 'firebase/app';
import UserDeviceAndBrowserInfo from '@/components/UserBrowserAndDeviceInfo';
import AvMedia from 'vue-audio-visual/src/components/AvMedia';
import { addInfoToLogRocket } from '@/services/logrocket';

export default {
  name: 'VideoChat',
  components: {
    UserDeviceAndBrowserInfo,
    RightDrawer,
  },
  data() {
    return {
      rtcClient: null,
      agoraVideoParams: {
        appId: null,
        channel: null,
        callStarted: false,
        screenShareResolution: 'default',
      },
      videoWrapper: null,
      uid: null,
      joined: false,
      localStream: null,
      remoteStreams: [],
      // Controls
      cameraActive: true,
      audioActive: true,
      recordingActive: false,
      recordingStarting: false,
      fullScreenActive: false,
      // Recording
      recordingParams: {},
      // Screen Sharing
      screenShareStartup: false,
      screenShareClient: null,
      shareStream: null,
      localScreenSharingActive: false,
      screenSharing: {
        screenId: null,
        active: false,
      },
      showUserInfo: false,
      selectedUserDeviceAndBrowserInfo: {
        osName: '',
        browserName: '',
        hasTouch: false,
      },
    };
  },
  computed: {
    getHelpCenterLink() {
      if (this.$store.state.device.isChrome) return 'https://support.google.com/chrome/answer/2693767?hl=no';
      else if (this.$store.state.device.isSafari) return 'https://support.apple.com/no-no/guide/safari/ibrw7f78f7fe/mac';
      else if (this.$store.state.device.isFirefox) {
        if (this.$store.state.device.osName === 'Mac OS') return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#firefox:mac:fx80';
        else if (this.$store.state.device.osName === 'Windows') return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#firefox:win10:fx80';
      }
      return 'https://learnlink.no/utdatert-nettleser';
    },
    screenSharingInProgress() {
      return this.screenSharing.active && !this.screenShareClient;
    },
  },
  created() {
    window.addEventListener('beforeunload', this.unloadHandler);
  },
  mounted() {
    console.info('mounted VideoChat');
    this.videoWrapper = document.querySelector('#js-video-wrapper');

    this.agoraVideoParams = {
      appId: credentials.agora.appId,
      channel: `${credentials.firebase.projectId}-${this.$route.params.roomID}`,
      callStarted: true,
      screenShareResolution: '720p_1',
    };

    this.rtcClient = AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
      areaCode: [AgoraRTC.AREAS.EUROPE],
    });

    this.rtcClient.init(this.agoraVideoParams.appId);

    // this.rtcClient.enableAudioVolumeIndicator();

    this.$db.ref('/boards/' + this.$route.params.roomID + '/screenSharing').on('value', (snapshot) => {
      this.screenSharing = snapshot.val();

      if (this.recordingActive) {
        agoraService.updateRecordingLayout({
          ...this.recordingParams,
          screenSharingActive: this.screenSharing.active,
          screenSharingUid: this.screenSharing.screenId + '',
        });
      }
    });

    screenfull.on('change', this.handleFullScreenChangeEvent);

    const pingDatabase = () =>
      this.$db
        .ref('/boards/' + this.$route.params.roomID)
        .update({ timestamp: firebase.database.ServerValue.TIMESTAMP });

    pingDatabase();

    // Ping the database every 15 seconds
    this.timestampInterval = setInterval(() => pingDatabase(), 15000);

    this.initEventListeners();
    this.join();

    // if (!this.$store.state.device.isSafari && !this.$store.state.device.hasTouch) {
    // this.rtcClient.enableDualStream(
    //   () => console.log('Dual stream enabled'),
    //   (e) => this.$handleError(e),
    // );
    // }

    this.listenForRecordingStateChange();
  },
  beforeDestroy() {
    screenfull.off('change', this.handleFullScreenChangeEvent);
    clearInterval(this.timestampInterval);
    if (this.recordingActive) {
      this.$db.ref('/boards/' + this.$route.params.roomID + '/usersRecording/' + this.uid).remove();
    }

    if (this.screenSharingActive) {
      this.stopScreenShare();
    }
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.unloadHandler);
  },
  methods: {
    ...mapMutations([
      'displayAlert',
      'setLoadingVideoChat',
      'setSessionRecordingStatus',
      'setVideoChatActive',
      'endCall',
      'setVideoInput',
      'setAudioInput',
    ]),
    //#region Agora methods
    join() {
      if (this.joined) {
        return;
      }

      this.rtcClient.join(
        null, // TODO: Implement getting token from the server
        this.agoraVideoParams.channel,
        this.agoraVideoParams.uid ?? null,
        (uid) => {
          this.uid = uid;
          this.joined = true;

          this.addBrowserInfoToDB(uid);

          addInfoToLogRocket({ screenId: uid });

          this.localStream = AgoraRTC.createStream({
            streamID: this.uid,
            audio: true,
            video: true,
            cameraId: this.$store.state.selectedInputDevices.video,
            microphoneId: this.$store.state.selectedInputDevices.audio,
            screen: false,
          });

          this.localStream.setVideoEncoderConfiguration({
            // The video resolution.
            resolution: {
              width: 640,
              height: 480,
            },
            // The video frame rate (fps). We recommend setting it as 15. Do not set it to a value greater than 30.
            frameRate: {
              min: 10,
              max: 15,
            },
            // The video bitrate (Kbps). Refer to the video profile table below to set this parameter.
            bitrate: {
              min: 500,
              max: 2000,
            },
          });

          this.localStream.init(
            () => {
              const agoraLocalContainer = document.querySelector('#js-local-stream');

              if (agoraLocalContainer.childNodes.length !== 0) {
                agoraLocalContainer.innerHTML = '';
              }

              this.localStream.play('js-local-stream');

              this.rtcClient.publish(this.localStream, (error) => {
                console.error(error);
              });

              this.setLoadingVideoChat(false);

              this.calculateSizes();

              this.createAudioVisualizer('js-local-stream', this.localStream);

              // Uncomment to use for debugging connection quality.
              // let self = this;
              // setInterval(function() {
              //   self.localStream.getStats((stats) => {
              //     console.log(`Local Stream accessDelay: ${stats.accessDelay}`);
              //     console.log(`Local Stream audioSendBytes: ${stats.audioSendBytes}`);
              //     console.log(`Local Stream audioSendPackets: ${stats.audioSendPackets}`);
              //     console.log(`Local Stream audioSendPacketsLost: ${stats.audioSendPacketsLost}`);
              //     console.log(`Local Stream videoSendBytes: ${stats.videoSendBytes}`);
              //     console.log(`Local Stream videoSendFrameRate: ${stats.videoSendFrameRate}`);
              //     console.log(`Local Stream videoSendPackets: ${stats.videoSendPackets}`);
              //     console.log(`Local Stream videoSendPacketsLost: ${stats.videoSendPacketsLost}`);
              //     console.log(`Local Stream videoSendResolutionHeight: ${stats.videoSendResolutionHeight}`);
              //     console.log(`Local Stream videoSendResolutionWidth: ${stats.videoSendResolutionWidth}`);
              //   });
              //
              //   if (self.remoteStreams.length) {
              //     // TODO: Use remoteStreams[i].elementID to map to videoStreamDiv
              //     self.remoteStreams[0].getStats((stats) => {
              //       console.log(`Remote Stream accessDelay: ${stats.accessDelay}`);
              //       console.log(`Remote Stream audioReceiveBytes: ${stats.audioReceiveBytes}`);
              //       console.log(`Remote Stream audioReceiveDelay: ${stats.audioReceiveDelay}`);
              //       console.log(`Remote Stream audioReceivePackets: ${stats.audioReceivePackets}`);
              //       console.log(`Remote Stream audioReceivePacketsLost: ${stats.audioReceivePacketsLost}`);
              //       console.log(`Remote Stream endToEndDelay: ${stats.endToEndDelay}`);
              //       console.log(`Remote Stream videoReceiveBytes: ${stats.videoReceiveBytes}`);
              //       console.log(`Remote Stream videoReceiveDecodeFrameRate: ${stats.videoReceiveDecodeFrameRate}`);
              //       console.log(`Remote Stream videoReceiveDelay: ${stats.videoReceiveDelay}`);
              //       console.log(`Remote Stream videoReceiveFrameRate: ${stats.videoReceiveFrameRate}`);
              //       console.log(`Remote Stream videoReceivePackets: ${stats.videoReceivePackets}`);
              //       console.log(`Remote Stream videoReceivePacketsLost: ${stats.videoReceivePacketsLost}`);
              //       console.log(`Remote Stream videoReceiveResolutionHeight: ${stats.videoReceiveResolutionHeight}`);
              //       console.log(`Remote Stream videoReceiveResolutionWidth: ${stats.videoReceiveResolutionWidth}`);
              //     });
              //     console.log(`\n\n`);
              //   }
              // }, 1000);
            },
            (error) => {
              this.$handleError('init local stream failed ', error);

              this.setLoadingVideoChat(false);

              this.calculateSizes();

              const alert = {
                headline: 'Mangler tilgang til kamera / mikrofon',
                message: 'Du må godta deling av video og lyd.',
                link: this.getHelpCenterLink,
              };

              this.displayAlert(alert);
            },
          );
        },
        (error) => {
          this.$handleError('client join failed', error);
        },
      );
    },
    leave(shouldRedirect = true) {
      this.rtcClient.leave(
        () => {
          this.localStream.close();
          if (this.shareStream) this.shareStream.close();

          this.localStream = null;

          this.remoteStreams = [];

          this.joined = false;
        },
        () => {
          console.error('Leaving channel failed');
        },
      );

      this.setVideoChatActive(false);

      if (shouldRedirect) {
        this.$router.push('/');
      }
    },
    switchToWhiteBoard() {
      this.leave(false);
      this.endCall();
    },
    initLocalStreamWithDefaultDevices() {
      this.localStream = AgoraRTC.createStream({
        streamID: this.uid,
        audio: true,
        video: true,
        screen: false,
      });

      this.localStream.init(
        () => {
          const agoraLocalContainer = document.querySelector('#js-local-stream');

          if (agoraLocalContainer.childNodes.length !== 0) {
            agoraLocalContainer.innerHTML = '';
          }

          this.localStream.play('js-local-stream');
          this.setLoadingVideoChat(false);

          this.calculateSizes();

          this.setVideoInput(true);
          this.setAudioInput(true);
        },
        (error) => {
          this.setLoadingVideoChat(false);

          this.calculateSizes();

          const alert = {
            headline: 'Mangler tilgang til kamera / mikrofon',
            message: 'Du må godta deling av video og lyd.',
            link: this.getHelpCenterLink,
          };
          this.displayAlert(alert);
          this.$handleError('init local stream failed ', error);
        },
      );
    },
    initEventListeners() {
      this.rtcClient.on('error', (error) => {
        console.log('error');
        this.$handleError(error);
      });

      // When a stream is added to the room
      this.rtcClient.on('stream-added', (event) => {
        console.log('stream-added');
        const stream = event.stream;
        const streamId = String(stream.getId());

        // Don't subscribe to own screenshare stream.
        if ((streamId !== String(this.localStream.getId())) &&
          (!this.shareStream || (streamId !== String(this.shareStream.getId())))) {
          this.rtcClient.subscribe(event.stream, this.$handleError);
        }
      });

      // When sucsessfully subscribed to a stream
      this.rtcClient.on('stream-subscribed', (event) => {
        console.log('stream-subscribed');
        const stream = event.stream;
        const streamId = String(stream.getId());

        this.remoteStreams.push(stream);
        this.addVideoStream(streamId);
        stream.play(streamId);
        this.createAudioVisualizer(streamId, stream);
        this.calculateSizes();
      });

      this.rtcClient.on('peer-leave', (event) => {
        console.log('peer-leave');

        this.removeVideoDiv(String(event.uid));
      });

      this.rtcClient.on('stream-removed', (event) => {
        console.log('stream-removed');
        const stream = event.stream;

        stream.close();

        this.removeVideoDiv(String(stream.getId()));
      });

      this.rtcClient.on('connection-state-changed', (event) => {
        console.log('connection-state-changed');
        console.log(event);
      });

      // this.rtcClient.on('volume-indicator', (event) => {
      // console.log('volume-indicator')
      //   event.attr.forEach((volume, index) => {
      //     console.log(`${index}: ${volume.uid} - Level: ${volume.level}`);
      //   });
      // });
    },
    removeVideoDiv(streamId) {
      this.remoteStreams = this.remoteStreams.filter((stream) => String(stream.getId()) !== streamId);

      const remDiv = document.getElementById(streamId);
      if (remDiv) remDiv.remove();

      this.calculateSizes();
    },
    async handleEndCall() {
      await this.stopRecording();
      this.leave();
      this.endCall();
    },
    handleToggleVideo() {
      if (this.cameraActive) {
        this.localStream.muteVideo();
        this.cameraActive = false;
      }
      else {
        this.localStream.unmuteVideo();
        this.cameraActive = true;
      }
    },
    handleToggleAudio() {
      if (this.audioActive) {
        this.localStream.muteAudio();
        this.audioActive = false;
      }
      else {
        this.localStream.unmuteAudio();
        this.audioActive = true;
      }
    },
    handleToggleScreenSharing() {
      if (this.localScreenSharingActive) {
        this.stopScreenShare();
      }
      else {
        this.startScreenShare();
      }
    },
    startScreenShare() {
      this.screenShareStartup = true;
      this.screenShareClient = AgoraRTC.createClient({
        mode: 'rtc',
        codec: 'vp8',
      });

      this.screenShareClient.init(this.agoraVideoParams.appId, () => {
        this.screenShareClient.join(
          null,
          this.agoraVideoParams.channel,
          this.screenSharing.screenId,
          () => {
            const streamSpec = {
              streamID: this.screenSharing.screenId,
              audio: false,
              video: false,
              screen: true,
            };

            if (this.$store.state.device.isFirefox) {
              streamSpec.mediaSource = 'screen';
            }

            this.shareStream = AgoraRTC.createStream(streamSpec);

            this.shareStream.on('stopScreenSharing', () => {
              this.stopScreenShare();
            });

            this.shareStream.init(
              () => {
                this.screenShareClient.publish(this.shareStream);

                this.$db.ref('/boards/' + this.$route.params.roomID + '/screenSharing').update({
                  active: true,
                });
                this.$db
                  .ref('/boards/' + this.$route.params.roomID + '/screenSharing')
                  .onDisconnect()
                  .update({
                    active: false,
                  });

                this.localScreenSharingActive = true;
                this.screenShareStartup = false;
              },
              (error) => {
                this.$handleError(error);
                this.stopScreenShare();
              },
            );
          },
          (error) => {
            this.$handleError(error);
          },
        );
      });
    },
    stopScreenShare() {
      if (!this.screenShareClient) {
        return;
      }

      this.screenShareClient.leave(
        async() => {
          this.shareStream.close();

          this.shareStream = null;
          this.screenShareClient = null;

          await Promise.all([
            this.$db.ref('/boards/' + this.$route.params.roomID + '/screenSharing').update({
              active: false,
            }),
            this.$db
              .ref('/boards/' + this.$route.params.roomID + '/screenSharing')
              .onDisconnect()
              .cancel(),
          ]);

          this.localScreenSharingActive = false;
          this.screenShareStartup = false;
        },
        (error) => {
          this.$handleError(error);
        },
      );
    },
    handleFullScreenChangeEvent() {
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        this.disableFullscreen();
      }
    },
    enableFullscreen() {
      this.fullScreenActive = true;

      const videoWrapper = document.getElementById('js-video-wrapper');

      // TODO: Fix full screen on Safari
      if (screenfull.isEnabled) {
        screenfull.request(videoWrapper);
      }

      videoWrapper.classList.add('video-wrapper--full-screen');
      document.querySelectorAll('.remote-video').forEach(el => el.classList.add('remote-video--full-screen'));

      this.calculateSizes();
    },
    disableFullscreen() {
      this.fullScreenActive = false;

      const videoWrapper = document.getElementById('js-video-wrapper');

      screenfull.exit();

      videoWrapper.classList.remove('video-wrapper--full-screen');
      document.querySelectorAll('.remote-video').forEach(el => el.classList.remove('remote-video--full-screen'));
      this.localStream.player.container.classList.remove('full-screen__publisher');

      this.calculateSizes();
    },
    async handleToggleRecording() {
      if (this.recordingActive) {
        await this.stopRecording();
      }
      else {
        await this.startRecording();
      }
    },
    async startRecording() {
      if (!this.recordingActive && !this.recordingStarting) {
        this.recordingStarting = true;
        try {
          this.recordingParams = {
            appid: this.agoraVideoParams.appId,
            cname: this.agoraVideoParams.channel,
            uid: random(1, 999999999).toString(),
            screenSharingUid: this.screenSharing.screenId,
            screenSharingActive: this.screenSharing.active,
          };

          const { resourceId } = await agoraService.acquireCloudResourceId(this.recordingParams);

          this.recordingParams.resourceId = resourceId;
          this.recordingParams.remoteStreams = this.remoteStreams.map(stream => stream.getId());

          const archiveResource = await agoraService.startCloudRecording(this.recordingParams);
          const fileList =
            'agoraVideo/' +
            archiveResource.sid +
            '_' +
            process.env.VUE_APP_FIREBASE_PROJECT_ID +
            '-' +
            this.$route.params.roomID +
            '.m3u8';

          const token = await this.$auth.currentUser.getIdToken();

          await axios.post(
            process.env.VUE_APP_API2 + '/recordings/' + archiveResource.sid,
            {
              fileList,
              fileListMode: 'string',
              uid: this.$store.state.user.uid,
            },
            {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            },
          );

          this.recordingParams.sid = archiveResource.sid;
          this.recordingActive = true;

          await this.$db.ref('/boards/' + this.$route.params.roomID + '/usersRecording').update({
            [this.uid]: true,
          });

          await this.$db
            .ref('/boards/' + this.$route.params.roomID + '/usersRecording')
            .onDisconnect()
            .update({
              [this.uid]: false,
            });

          this.recordingStarting = false;
        }
        catch (error) {
          this.recordingStarting = false;
          this.$emit('errorStartingRecording');
          this.$handleError(error);
        }
      }
    },
    async stopRecording() {
      if (this.recordingActive) {
        try {
          await agoraService.stopCloudRecording(this.recordingParams);

          this.recordingActive = false;

          this.$db.ref('/boards/' + this.$route.params.roomID + '/usersRecording').update({
            [this.uid]: false,
          });
          await this.$db
            .ref('/boards/' + this.$route.params.roomID + '/usersRecording')
            .onDisconnect()
            .cancel();
        }
        catch (error) {
          this.$handleError(error);
        }
      }
    },
    //#endregion
    //#region Firebase methods
    listenForRecordingStateChange() {
      this.$db.ref('/boards/' + this.$route.params.roomID + '/usersRecording').on('value', (snapshot, err) => {
        if (err) {
          this.$handleError(err);
        }
        else {
          const usersRecordingObj = snapshot.val();
          if (usersRecordingObj) {
            let sessionsIsRecorded = false;
            Object.keys(usersRecordingObj).forEach((uid) => {
              const playerElement = document.querySelector(`#player_${uid}`);
              if (!playerElement) {
                return;
              }
              if (usersRecordingObj[uid]) {
                sessionsIsRecorded = true;
                playerElement.classList.add('is-recording-border');
              }
              else {
                playerElement.classList.remove('is-recording-border');
              }
            });
            this.setSessionRecordingStatus(sessionsIsRecorded);
          }
        }
      });
    },
    //#endregion
    //#region Helper methods
    async addBrowserInfoToDB(uid) {
      // FIXME: window.analytics.user is not a function
      const userUID = window.analytics.user().anonymousId();
      try {
        await this.$db.ref('/boards/' + this.$route.params.roomID + '/users').child(userUID).update({
          screenId: uid,
          browserName: this.$store.state.device.browserName,
          hasTouch: this.$store.state.device.hasTouch,
          osName: this.$store.state.device.osName,
        });
      }
      catch (e) {
        this.$handleError(e);
      }
    },
    addVideoStream(elementId) {
      const streamDiv = document.createElement('div');

      streamDiv.id = elementId;
      streamDiv.className = 'remote-video';

      if (elementId === String(this.screenSharing.screenId)) {
        streamDiv.classList.add('remote-video--screen-share');
      }

      document.getElementById('js-video-wrapper').appendChild(streamDiv);
      this.createUserDeviceInfoButton(elementId);
    },
    calculateSizes() {
      console.log('calculateSizes');
      const subscriberElements = document.querySelectorAll('.remote-video');
      const activeWindows = document.querySelector('#js-video-wrapper').childNodes;
      const windowsCount = activeWindows.length;

      const container = this.localStream.player ? this.localStream.player.container : null;

      if (this.fullScreenActive && container) {
        if (!subscriberElements.length) {
          container.style.height = '100%';
          container.style.width = '100%';
        }
        else if (this.screenSharingInProgress) {
          console.log('screenSharingInProgress');
          this.enableFullscreenShareLayout(subscriberElements);
        }
        else {
          container.classList.add('full-screen__publisher');

          for (let subscriber of subscriberElements) {
            subscriber.style.height = '100%';
            subscriber.style.width = `${Math.fround(100 / subscriberElements.length)}%`;
          }
        }
      }
      else {
        this.disableFullscreenShareLayout(subscriberElements);
        const videoWidth = 350;
        let width;
        if (windowsCount < 6) width = videoWidth + 'px';
        else if (windowsCount < 12) width = videoWidth * 2 + 'px';
        else if (windowsCount < 18) width = videoWidth * 3 + 'px';
        else if (windowsCount < 24) width = videoWidth * 4 + 'px';
        else if (windowsCount < 30) width = videoWidth * 5 + 'px';
        else if (windowsCount < 36) width = videoWidth * 6 + 'px';
        document.getElementById('js-video-wrapper').style.width = width;

        if (windowsCount < 6) {
          this.width = 100;
          this.height = 267 - 17 * windowsCount;
        }
        if (windowsCount >= 6) {
          this.width = 50;
          this.height = 150;
        }

        for (let i = 0; i < windowsCount; i++) {
          if (activeWindows[i].classList.contains('remote-video--screen-share')) {
            break;
          }
          if (activeWindows[i].childNodes[0]) {
            activeWindows[i].style.height = `${this.height}px`;
            activeWindows[i].style.width = `${this.width}%`;
            activeWindows[i].childNodes[0].style.borderRadius = '4px';
          }
        }

        const videoWrapper = document.getElementById('js-video-wrapper');

        if (this.screenSharingInProgress) {
          videoWrapper.classList.add('video-wrapper--screen-share');
        }
        else {
          videoWrapper.classList.remove('video-wrapper--screen-share');
        }
      }
    },
    enableFullscreenShareLayout(subscriberElements) {
      this.localStream.player && this.localStream.player.container.classList.add('full-screen__publisher');

      const shareScreenElement = document.querySelector('.remote-video--screen-share');
      shareScreenElement?.classList.add('remote-video--screen-share-full');

      for (let subscriber of subscriberElements) {
        if (!subscriber.classList.contains('remote-video--screen-share-full')) {
          subscriber.style.display = 'none';
        }
      }
    },
    disableFullscreenShareLayout(subscriberElements) {
      this.localStream.player && this.localStream.player.container.classList.remove('full-screen__publisher');

      const shareScreenElement = document.querySelector('.remote-video--screen-share');
      shareScreenElement?.classList.remove('remote-video--screen-share-full');

      for (let subscriber of subscriberElements) {
        if (!subscriber.classList.contains('remote-video--screen-share-full')) {
          subscriber.style.display = 'block';
        }
      }
    },
    unloadHandler(event) {
      if (this.localScreenSharingActive || this.recordingActive) {
        this.stopScreenShare();
        this.stopRecording();

        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      }
    },
    createUserDeviceInfoButton(elementId) {
      const queryRef = this.$db.ref('/boards/' + this.$route.params.roomID + '/users').orderByChild('screenId').equalTo(parseInt(elementId));

      queryRef.on('value', (querySnap) => {
        if (querySnap.val()) {
          const userInfoFromDB = Object.values(querySnap.val())[0];

          const selectedUserDeviceAndBrowserInfo = {
            osName: userInfoFromDB.osName,
            browserName: userInfoFromDB.browserName,
            hasTouch: userInfoFromDB.hasTouch,
          };
          const streamDiv = document.getElementById(elementId);
          const btn = document.createElement('I');

          btn.classList.add('material-icons');
          btn.append('information');
          btn.classList.add('info-button');

          btn.onclick = () => {
            this.selectedUserDeviceAndBrowserInfo = selectedUserDeviceAndBrowserInfo;
            this.showUserInfo = !this.showUserInfo;
          };

          if (streamDiv) {
            streamDiv.appendChild(btn);
          }
        }
      });
    },
    createAudioVisualizer(elementId, stream) {
      const streamDiv = document.getElementById(elementId);

      let AvMediaClass = Vue.extend(AvMedia);
      let instance = new AvMediaClass({
        propsData: {
          type: 'frequ',
          lineColor: 'white',
        },
      });

      instance.$mount();
      streamDiv.appendChild(instance.$el);

      instance.$props.media = stream.stream;
      instance.$el.classList.add('audio-visualizer');
    },
    //#endregion
  },
};
</script>

<style lang="scss">
.video-wrapper {
  right: 60px;
  position: fixed;
  display: flex !important;
  flex-direction: column;
}

@media (max-width: 600px) {
  .video-wrapper {
    max-height: 85vh;
    flex-wrap: nowrap;
    right: calc(50% - 175px);
  }
}

.video-wrapper--full-screen {
  flex-direction: row;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100vh;
  max-height: 100vh;
}

.local-stream {
  position: relative;
  display: flex;
  margin: 1px;
}

.remote-video {
  position: relative;
  display: flex;
  margin: 1px;
  min-width: 200px !important;
  border-radius: 0 !important;
}

.remote-video--full-screen {
  margin: 0 !important;
}

.remote-video--screen-share {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 50px;
  right: 420px;
  z-index: 101;

  video {
    object-fit: contain !important;
  }
}

@media (max-width: 1200px) {
  .video-wrapper--screen-share {
    flex-direction: row;

    .remote-video {
      width: 350px;
    }

    .remote-video--screen-share {
      pointer-events: none;
      width: 100%;
    }
  }
}

.v-skeleton-loader__bone {
  height: 250px;
}

.is-recording-border {
  border: 2px solid #d32f2f;
}

.full-screen__publisher {
  position: fixed !important;
  top: 20px;
  right: 20px;
  z-index: 2;
  height: 100px !important;
  width: 160px !important;
}

.remote-video--screen-share-full {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
}

.back-button {
  right: 10px;
  bottom: 68px;
  position: fixed;
  z-index: 1;
  font-size: 12px;
  color: grey;
}

.info-button {
  color: white;
  font-size: 18px;
  margin: 5px -5px;
  background: transparent;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.audio-visualizer {
  z-index: 100;
  position: absolute;
  bottom: -25px;
  left: 25px;
}
</style>
