<template>
  <v-card>
    <v-layout>
      <video-tester />
    </v-layout>

    <v-card-text class="text-center">
      <h4>Venter du på noen?</h4>
      <div class="d-flex justify-center align-center">
        <h6>
          Inviter dem til
          <strong>{{ roomUrl }}</strong>
        </h6>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small icon class="ml-3" v-bind="attrs" v-on="on" @click="copyRoomURL">
              <v-icon>content_copy</v-icon>
            </v-btn>
          </template>
          <span>Kopier link</span>
        </v-tooltip>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn color="info" class="ma-auto" @click="enterRoom">
        Start nettundervisning
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="success">
      <v-icon>done</v-icon>
      Link kopiert
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Lukk
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import VideoTester from './VideoTester';

export default {
  name: 'WelcomeDialog',
  components: {
    VideoTester,
  },
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    roomUrl() {
      return window.location.href;
    },
  },
  methods: {
    enterRoom() {
      this.$emit('startVideoChat');
    },
    copyRoomURL() {
      this.snackbar = true;
      navigator.clipboard.writeText(this.roomUrl);
    },
  },
};
</script>
