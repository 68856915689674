var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.videoChat.active
    ? _c(
        "div",
        [
          _c(
            "v-navigation-drawer",
            {
              attrs: {
                fixed: "",
                right: "",
                permanent: "",
                width: "200px",
                "expand-on-hover": !_vm.$store.state.device.hasTouch,
                color: "rgba(255, 255, 255, 0.95)",
                "mini-variant": _vm.drawerClosed
              }
            },
            [
              _vm.user.uid
                ? _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "user-column",
                                  style: {
                                    "margin-left":
                                      (_vm.drawerClosed ? -8 : 10) + "px"
                                  }
                                },
                                [
                                  _c("profile-photo", {
                                    attrs: {
                                      "photo-u-r-l": _vm.user.photoURL,
                                      round: true
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.drawerClosed
                                ? _c("v-col", { attrs: { cols: "8" } }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.user.displayName) + " "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openLoginDialog()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("account_circle")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Logg inn")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c("v-divider"),
              _c(
                "v-list",
                { attrs: { nav: "", dense: "" } },
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.endCall()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "red darken-3" } }, [
                            _vm._v("call_end")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { attrs: { color: "red darken-3" } },
                        [_vm._v("Avslutt samtale")]
                      )
                    ],
                    1
                  ),
                  !_vm.$store.state.selectedInputDevices.video
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { disabled: "" } },
                                              [_vm._v("videocam_off")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _c(
                                            "span",
                                            { staticClass: "text--disabled" },
                                            [_vm._v(" Kamera på ")]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            894066673
                          )
                        },
                        [_c("span", [_vm._v(" Finner ikke kamera ")])]
                      )
                    : _vm._e(),
                  _vm.$store.state.selectedInputDevices.video
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toggleVideo()
                            }
                          }
                        },
                        [
                          _vm.cameraActive
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "blue darken-3" } },
                                    [_vm._v("videocam")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.cameraActive
                            ? _c("v-list-item-title", [_vm._v(" Kamera av ")])
                            : _vm._e(),
                          !_vm.cameraActive
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red darken-3" } },
                                    [_vm._v("videocam_off")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.cameraActive
                            ? _c("v-list-item-title", [_vm._v(" Kamera på ")])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.$store.state.selectedInputDevices.audio
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { disabled: "" } },
                                              [_vm._v("mic_off")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _c(
                                            "span",
                                            { staticClass: "text--disabled" },
                                            [_vm._v(" Mikrofon på ")]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1198611116
                          )
                        },
                        [_c("span", [_vm._v(" Finner ikke mikrofon ")])]
                      )
                    : _vm._e(),
                  _vm.$store.state.selectedInputDevices.audio
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toggleAudio()
                            }
                          }
                        },
                        [
                          _vm.audioActive
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "blue darken-3" } },
                                    [_vm._v("mic")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.audioActive
                            ? _c("v-list-item-title", [_vm._v(" Mikrofon av ")])
                            : _vm._e(),
                          !_vm.audioActive
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red darken-3" } },
                                    [_vm._v("mic_off")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.audioActive
                            ? _c("v-list-item-title", [_vm._v(" Mikrofon på ")])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.$store.state.selectedInputDevices.video
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { disabled: "" } },
                                              [_vm._v("fullscreen")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _c(
                                            "span",
                                            { staticClass: "text--disabled" },
                                            [_vm._v(" Fullskjerm ")]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1755590559
                          )
                        },
                        [_c("span", [_vm._v(" Finner ikke kamera ")])]
                      )
                    : _vm._e(),
                  _vm.$store.state.selectedInputDevices.video
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.enableFullscreen()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "blue darken-3" } },
                                [_vm._v("fullscreen")]
                              )
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Fullskjerm")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSafari
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { disabled: true } },
                                              [_vm._v(" screen_share ")]
                                            )
                                          ],
                                          1
                                        ),
                                        !_vm.isMobile
                                          ? _c("v-list-item-title", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text--disabled"
                                                },
                                                [_vm._v(" Del skjerm ")]
                                              )
                                            ])
                                          : _c("v-list-item-title", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text--disabled"
                                                },
                                                [_vm._v(" Ikke tilgjengelig ")]
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1088095313
                          )
                        },
                        [_c("span", [_vm._v(" Støttes ikke av Safari ")])]
                      )
                    : _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.screenSharingInProgress ||
                              _vm.isMobile ||
                              _vm.screenShareStartup
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleScreenSharing()
                            }
                          }
                        },
                        [
                          !_vm.screenSharingActive
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.screenSharingInProgress ||
                                          _vm.isMobile,
                                        color: "blue darken-3"
                                      }
                                    },
                                    [_vm._v("screen_share")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.screenSharingActive && !_vm.isMobile
                            ? _c("v-list-item-title", [_vm._v(" Del skjerm ")])
                            : _vm.screenSharingActive
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red darken-3" } },
                                    [_vm._v("stop_screen_share")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.screenSharingActive
                            ? _c("v-list-item-title", [
                                _vm._v(" Avslutt skjermdeling ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled: !_vm.user.uid || _vm.recordingStarting
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleRecording()
                        }
                      }
                    },
                    [
                      !_vm.recordingActive
                        ? _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    disabled: _vm.recordingStarting,
                                    color: "blue darken-3"
                                  }
                                },
                                [_vm._v("lens")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.user.uid
                        ? _c("v-list-item-title", [
                            _vm._v(" Logg inn for å starte opptak ")
                          ])
                        : _vm.recordingStarting
                        ? _c("v-list-item-title", [_vm._v(" Starter opptak ")])
                        : !_vm.recordingActive
                        ? _c("v-list-item-title", [_vm._v(" Start opptak ")])
                        : _vm._e(),
                      _vm.recordingActive
                        ? _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "red darken-3" } },
                                [_vm._v("lens")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.recordingActive
                        ? _c("v-list-item-title", [_vm._v(" Stopp opptak ")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { disabled: !_vm.user.uid },
                      on: {
                        click: function($event) {
                          return _vm.openUserDrawer()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("video_library")])],
                        1
                      ),
                      !_vm.user.uid
                        ? _c("v-list-item-title", [
                            _vm._v(" Logg inn for å se tidligere opptak ")
                          ])
                        : _c("v-list-item-title", [
                            _vm._v(" Se tidligere opptak ")
                          ])
                    ],
                    1
                  ),
                  !_vm.$store.state.selectedInputDevices.video ||
                  !_vm.$store.state.selectedInputDevices.audio
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.reconnect()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("video_call")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Koble til på nytt")])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.user.uid
                ? _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { link: "" }, on: { click: _vm.$signOut } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("exit_to_app")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v(" Logg ut ")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.$store.state.device.hasTouch
            ? _c(
                "v-btn",
                {
                  staticClass: "drawer-toggle-button",
                  style: {
                    right: _vm.toggleButtonPosition
                  },
                  attrs: {
                    fab: "",
                    small: "",
                    depressed: "",
                    dark: "",
                    color: "#009bff"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggleDrawer()
                    }
                  }
                },
                [
                  _vm.drawerClosed
                    ? _c("v-icon", [_vm._v(" keyboard_arrow_left ")])
                    : _c("v-icon", [_vm._v(" keyboard_arrow_right ")])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }