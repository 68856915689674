var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "div",
        [
          _c("right-drawer", {
            attrs: {
              cameraActive: _vm.cameraActive,
              audioActive: _vm.audioActive,
              recordingActive: _vm.recordingActive,
              recordingStarting: _vm.recordingStarting,
              screenSharingActive: _vm.localScreenSharingActive,
              screenSharingInProgress: _vm.screenSharingInProgress,
              screenShareStartup: _vm.screenShareStartup
            },
            on: {
              endCall: _vm.handleEndCall,
              toggleVideo: _vm.handleToggleVideo,
              toggleAudio: _vm.handleToggleAudio,
              toggleRecording: _vm.handleToggleRecording,
              toggleScreenSharing: _vm.handleToggleScreenSharing,
              enableFullscreen: _vm.enableFullscreen,
              attemptReconnecting: _vm.initLocalStreamWithDefaultDevices
            }
          })
        ],
        1
      ),
      _vm.$vuetify.breakpoint.xsOnly
        ? _c(
            "v-btn",
            {
              staticClass: "back-button",
              attrs: { fab: "", color: "white" },
              on: { click: _vm.switchToWhiteBoard }
            },
            [_c("v-icon", [_vm._v(" gesture ")])],
            1
          )
        : _vm._e(),
      _c("user-device-and-browser-info", {
        attrs: {
          "show-user-info": _vm.showUserInfo,
          "selected-user-device-and-browser-info":
            _vm.selectedUserDeviceAndBrowserInfo
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "video-wrapper", attrs: { id: "js-video-wrapper" } },
      [
        _c("div", {
          staticClass: "local-stream",
          attrs: { id: "js-local-stream" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }