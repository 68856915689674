<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Info om brukerens enhet
        </v-card-title>

        <v-card-text>
          Enhet: {{ selectedUserDeviceAndBrowserInfo.osName }} <br>
          Nettleser: {{ selectedUserDeviceAndBrowserInfo.browserName }} <br>
          Touch: {{ selectedUserDeviceAndBrowserInfo.hasTouch }} <br>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Lukk
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "UserDeviceAndBrowserInfo",
  props: {
    showUserInfo: {
      type: Boolean,
      default: false
    },
    selectedUserDeviceAndBrowserInfo: {
      type: Object,
      default() {
        return {
          osName: '',
          browserName: '',
          hasTouch: false
        }
      }
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    showUserInfo() {
      this.dialog = true;
    }
  }
}
</script>

<style scoped>

</style>
