var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errorMessage,
              expression: "errorMessage"
            }
          ],
          staticClass: "error-message headline text-center red--text"
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.errorMessage,
                    expression: "!errorMessage"
                  }
                ],
                staticClass: "video-container",
                attrs: { id: "js-video-test" }
              },
              [_c("video", { attrs: { autoplay: "true" } })]
            )
          ]),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-select", {
                attrs: {
                  label: "Velg kamera",
                  items: _vm.availableVideoDeviceLabels
                },
                model: {
                  value: _vm.selectedVideoDevice,
                  callback: function($$v) {
                    _vm.selectedVideoDevice = $$v
                  },
                  expression: "selectedVideoDevice"
                }
              }),
              _c("v-select", {
                attrs: {
                  label: "Velg mikrofon",
                  items: _vm.availableAudioDeviceLabels
                },
                model: {
                  value: _vm.selectedAudioDevice,
                  callback: function($$v) {
                    _vm.selectedAudioDevice = $$v
                  },
                  expression: "selectedAudioDevice"
                }
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mic-icon",
                      attrs: { color: "#009bff", size: "30px" }
                    },
                    [_vm._v(" mic ")]
                  ),
                  _vm.videoStream !== false
                    ? _c("av-media", {
                        attrs: {
                          media: _vm.videoStream,
                          type: "frequ",
                          "line-color": "#009bff"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }