var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                _vm._v(" Info om brukerens enhet ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Enhet: " +
                    _vm._s(_vm.selectedUserDeviceAndBrowserInfo.osName) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " Nettleser: " +
                    _vm._s(_vm.selectedUserDeviceAndBrowserInfo.browserName) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " Touch: " +
                    _vm._s(_vm.selectedUserDeviceAndBrowserInfo.hasTouch) +
                    " "
                ),
                _c("br")
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" Lukk ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }