import axios from 'axios'

function generateToken() {
  return Buffer.from(
    `${process.env.VUE_APP_AGORA_CUSTOMER_ID}:${process.env.VUE_APP_AGORA_CUSTOMER_CERTIFICATE}`,
    'utf8'
  ).toString('base64')
}

function onRequest(config) {
  const token = generateToken()

  config.headers = {
    Authorization: 'Basic ' + token,
  }
  return config
}

function httpClient() {
  const instance = axios.create()

  instance.interceptors.request.use(onRequest)

  return instance
}

export default httpClient()
