<template>
  <div class="wrapper">
    <div
      v-show="errorMessage"
      class="error-message headline text-center red--text"
    >
      {{ errorMessage }}
    </div>

    <v-row>
      <v-col cols="6">
        <div
          v-show="!errorMessage"
          id="js-video-test"
          class="video-container"
        >
          <video autoplay="true" />
        </div>
      </v-col>
      <v-col cols="6">
        <v-select
          label="Velg kamera"
          v-model="selectedVideoDevice"
          :items="availableVideoDeviceLabels"
        />
        <v-select
          label="Velg mikrofon"
          v-model="selectedAudioDevice"
          :items="availableAudioDeviceLabels"
        />

        <v-row>
          <v-icon
            class="mic-icon"
            color="#009bff"
            size="30px"
          >
            mic
          </v-icon>
          <av-media
            v-if="videoStream !== false"
            :media="videoStream"
            type="frequ"
            line-color="#009bff"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      loading: true,
      videoStream: false,
      setTimeoutStart: null,
      errorMessage: null,
      selectedVideoDevice: null,
      selectedAudioDevice: null,
      availableVideoDevices: [],
      availableAudioDevices: [],
    };
  },
  computed: {
    availableAudioDeviceLabels() {
      return this.availableAudioDevices.map((device) => ({
        text: device.label,
        value: device.deviceId,
      }));
    },
    availableVideoDeviceLabels() {
      return this.availableVideoDevices.map((device) => ({
        text: device.label,
        value: device.deviceId,
      }));
    },
    device() {
      return this.$store.state.device;
    },
    getHelpCenterLink() {
      if (this.device.isChrome) return 'https://support.google.com/chrome/answer/2693767?hl=no';
      else if (this.device.isSafari) return 'https://support.apple.com/no-no/guide/safari/ibrw7f78f7fe/mac';
      else if (this.device.isFirefox) {
        if (this.device.osName === 'Mac OS') return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#firefox:mac:fx80';
        else if (this.device.osName === 'Windows') return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#firefox:win10:fx80';
      }
      return 'https://learnlink.no/utdatert-nettleser';
    },
  },
  beforeDestroy() {
    console.info('beforeDestroy()');
    this.loading && clearTimeout(this.setTimeoutStart);
    this.stopTest(); // Stop all @audio, @video streams after testing
  },
  async mounted() {
    console.info('mounted()');
    this.loading = false;

    // Create video element
    const videoElem = document.querySelector('video');

    // Initiate video preview
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: this.selectedAudioDevice },
        video: {
          deviceId: this.selectedVideoDevice,
        },
      });

      await this.listDevices();

      this.videoStream = stream;

      videoElem.srcObject = stream;
      videoElem.muted = true;
    }
    catch (e) {
      if (e.name === 'NotAllowedError') {
        const alert = {
          headline: 'Mangler tilgang til kamera / mikrofon',
          message: 'Du må godta deling av video og lyd.',
          link: this.getHelpCenterLink,
        };
        this.displayAlert(alert);
      }

      if (e.name === 'NotFoundError' || e.name === 'NotReadableError') {
        const alert = {
          headline: 'Finner ikke kamera',
          message: 'Nettleseren finner ikke kameraet ditt.',
          link: this.getHelpCenterLink,
        };
        this.displayAlert(alert);
      }

      if (e.name === 'TypeError' && e.message === 'undefined is not an object (evaluating \'navigator.mediaDevices.getUserMedia\')') {
        const alert = {
          headline: 'Nettleseren din støtter ikke video',
          message: 'Du kan ikke bruke kamera på denne enheten med denne nettleseren.',
        };
        if (this.$store.getters.isNonSafariOniOS) alert.message = 'Du må bruke siste versjon av Safari når du er på iPhone eller iPad';
        this.displayAlert(alert);
      }
      this.$handleError(e);
    }
  },
  methods: {
    ...mapMutations(['displayAlert', 'setVideoInput', 'setAudioInput']),

    async requestDeviceChange() {
      console.info('requestDeviceChange()');
      if (this.availableVideoDevices.length > 0) {
        this.errorMessage = null;

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: this.selectedAudioDevice },
          video: {
            deviceId: this.selectedVideoDevice,
          },
        });

        const videoElem = document.querySelector('video');

        if (this.videoStream) {
          this.videoStream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        if (videoElem) {
          videoElem.srcObject = stream;
        }
        this.videoStream = stream;
      }
    },
    async listDevices() {
      console.info('listDevices()');
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.info(devices);

      this.availableAudioDevices = devices.filter((device) => device.kind === 'audioinput');
      this.availableVideoDevices = devices.filter((device) => device.kind === 'videoinput');

      console.info('availableAudioDevices');
      console.info(this.availableAudioDevices);

      console.info('availableVideoDevices');
      console.info(this.availableVideoDevices);

      if (this.availableVideoDevices.length > 0 && !this.selectedVideoDevice) {
        this.selectedVideoDevice = this.availableVideoDeviceLabels[0].value;
      }

      if (this.availableAudioDevices.length > 0 && !this.selectedAudioDevice) {
        this.selectedAudioDevice = this.availableAudioDeviceLabels[0].value;
      }

      if (!this.availableVideoDevices.find((device) => device.deviceId === this.selectedVideoDevice)) {
        this.selectedVideoDevice =
          this.availableVideoDevices.length > 0 ? this.availableVideoDeviceLabels[0].value : null;
      }
      if (!this.availableAudioDevices.find((device) => device.deviceId === this.selectedAudioDevice)) {
        this.selectedAudioDevice =
          this.availableAudioDevices.length > 0 ? this.availableAudioDeviceLabels[0].value : null;
      }
    },
    stopTest() {
      console.info('stopTest()');
      if (this.videoStream) {
        this.videoStream.getTracks().map((track) => {
          track.stop();
        });
        this.videoStream.getAudioTracks().map((track) => {
          track.stop();
        });
        this.videoStream.getVideoTracks().map((track) => {
          track.stop();
        });
      }
    },
  },
  watch: {
    async selectedVideoDevice(val) {
      await this.requestDeviceChange();
      this.setVideoInput(val);
    },
    async selectedAudioDevice(val) {
      await this.requestDeviceChange();
      this.setAudioInput(val);
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper
  padding: 20px
  margin: auto
  overflow: hidden

.loader
  margin: 10px auto 0

.error-message
  margin: 40px auto
  color: #DD4B39

.video-container
  background-color: #fff
  padding: 10px 0

  video
    width: 100%
    max-height: 300px
    display: flex
    justify-content: space-around
    border-radius: 4px
    margin: 0 auto
    transform: scaleX(-1)

.mic-icon
  margin-top: -10px
  float: left
  max-height: 300px
</style>
