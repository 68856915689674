var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _vm.whiteboardParams.showTextControls
            ? _c(
                "v-flex",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "text-toolbar",
                      class: {
                        "mt-7": _vm.$vuetify.breakpoint.mdAndDown,
                        "mt-0": _vm.$vuetify.breakpoint.mdAndUp
                      },
                      attrs: { dense: "", flat: "" }
                    },
                    [
                      _c("v-overflow-btn", {
                        staticClass: "pa-0",
                        attrs: {
                          items: _vm.whiteboardParams.fontTypes,
                          label: "Font",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.whiteboardParams.fontFamily,
                          callback: function($$v) {
                            _vm.$set(_vm.whiteboardParams, "fontFamily", $$v)
                          },
                          expression: "whiteboardParams.fontFamily"
                        }
                      }),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c("v-overflow-btn", {
                        staticClass: "pa-0",
                        attrs: {
                          items: _vm.whiteboardParams.textSizes,
                          editable: "",
                          label: "Størrelse",
                          "hide-details": "",
                          overflow: ""
                        },
                        model: {
                          value: _vm.whiteboardParams.textSize,
                          callback: function($$v) {
                            _vm.$set(_vm.whiteboardParams, "textSize", $$v)
                          },
                          expression: "whiteboardParams.textSize"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-flex", [_c("canvas", { attrs: { id: "whiteboard" } })])
        ],
        1
      ),
      !_vm.helperDrawerActive && !_vm.userDrawerActive
        ? _c(
            "v-layout",
            {
              staticClass: "whiteboard-controls-container",
              attrs: { type: "flex", justify: "center" }
            },
            [
              _c("whiteboard-controls", {
                attrs: {
                  "whiteboard-params": _vm.whiteboardParams,
                  canvas: _vm.canvas
                },
                on: {
                  setLineWidth: _vm.handleSetLineWidth,
                  setActiveTool: _vm.handleSetActiveTool,
                  setLineColor: _vm.handleSetLineColor,
                  setPencilLineColor: _vm.handleSetPencilLineColor,
                  setPausePanning: _vm.handleSetPausePanning,
                  setIsDrawingMode: _vm.handleSetIsDrawingMode,
                  clearCanvas: _vm.handleClearCanvas,
                  zoomToPoint: _vm.handleZoomToPoint
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }