var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#fff", height: "50px" } },
        [
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c("v-btn", _vm._g({ attrs: { icon: "" } }, on), [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    style: {
                                      color:
                                        _vm.whiteboardParams.pencilLineColor
                                    }
                                  },
                                  [_vm._v(" palette ")]
                                ),
                                _c("p", [_c("strong", [_vm._v("Farger")])])
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-list",
                    {
                      staticStyle: {
                        width: "165px",
                        "flex-flow": "unset",
                        "flex-wrap": "wrap"
                      }
                    },
                    _vm._l(_vm.whiteboardParams.colorItems, function(
                      item,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "list-item",
                          staticStyle: {
                            width: "auto",
                            padding: "10px",
                            "box-sizing": "content-box"
                          },
                          attrs: { flex: "", "justify-center": "" },
                          on: {
                            click: function($event) {
                              return _vm.activateTool("drawing", item)
                            }
                          }
                        },
                        [
                          _c("span", {
                            style: {
                              display: "block",
                              width: "35px",
                              height: "35px",
                              backgroundColor: item
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c("v-btn", _vm._g({ attrs: { icon: "" } }, on), [
                            _c(
                              "div",
                              [
                                _c("v-icon", [_vm._v(" line_weight ")]),
                                _c("p", [_c("strong", [_vm._v("Tykkelse")])])
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "slider-wrapper" },
                    [
                      _c("v-slider", {
                        attrs: {
                          min: "2",
                          max: "80",
                          "append-icon": "add",
                          "prepend-icon": "remove",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.lineWidthModel,
                          callback: function($$v) {
                            _vm.lineWidthModel = $$v
                          },
                          expression: "lineWidthModel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    color:
                      _vm.whiteboardParams.activeTool === "drawing"
                        ? "#009bff"
                        : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.activateTool("drawing")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v(" create ")]),
                      _c("p", [_c("strong", [_vm._v("Penn")])])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    color:
                      _vm.whiteboardParams.activeTool === "superEraser"
                        ? "#009bff"
                        : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.activateTool("superEraser")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { staticStyle: { transform: "rotate(-180deg)" } },
                        [_vm._v(" edit ")]
                      ),
                      _c("p", [_c("strong", [_vm._v("Slett")])])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    color:
                      _vm.whiteboardParams.activeTool === "selectElement"
                        ? "#009bff"
                        : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.activateTool("selectElement")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v(" touch_app ")]),
                      _c("p", [_c("strong", [_vm._v("Velge")])])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    color:
                      _vm.whiteboardParams.activeTool === "moveBoard"
                        ? "#009bff"
                        : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.activateTool("moveBoard")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v(" open_with ")]),
                      _c("p", [_c("strong", [_vm._v("Flytt")])])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    color:
                      _vm.whiteboardParams.activeTool === "addText"
                        ? "#009bff"
                        : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.activateTool("addText")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v(" title ")]),
                      _c("p", [_c("strong", [_vm._v("Tekst")])])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", disabled: !_vm.objectsToDelete },
                  on: { click: _vm.clearCanvas }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v(" delete ")]),
                      _c("p", [_c("strong", [_vm._v("Slett alt")])])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          style: {
            bottom: "75px",
            left: _vm.$vuetify.breakpoint.smAndUp ? "30px" : "50px"
          },
          attrs: {
            fab: "",
            fixed: "",
            bottom: "",
            small: "",
            color: "transparent"
          },
          on: { click: _vm.zoomIn }
        },
        [_c("v-icon", { attrs: { color: "black" } }, [_vm._v(" add ")])],
        1
      ),
      _c(
        "v-btn",
        {
          style: {
            bottom: "75px",
            left: _vm.$vuetify.breakpoint.smAndUp ? "-20px" : "0"
          },
          attrs: {
            fab: "",
            fixed: "",
            bottom: "",
            small: "",
            color: "transparent"
          },
          on: { click: _vm.zoomOut }
        },
        [_c("v-icon", { attrs: { color: "black" } }, [_vm._v(" remove ")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }