import httpClient from './httpClient'
import credentials from '../config/credentials'

export async function acquireCloudResourceId({ cname, uid, appid }) {
  const payload = {
    cname,
    uid,
    clientRequest: { resourceExpiredHour: 12 },
  }

  const { data } = await httpClient.post(
    `https://api.agora.io/v1/apps/${appid}/cloud_recording/acquire`,
    payload
  )

  return data
}

export async function startCloudRecording({
  cname,
  uid,
  appid,
  resourceId,
  screenSharingUid,
  screenSharingActive,
}) {
  const payload = {
    cname,
    uid,
    clientRequest: {
      recordingConfig: {
        channelType: 1,
        subscribeUidGroup: 1,
        transcodingConfig: {
          width: 1280,
          height: 720,
          fps: 30,
          bitrate: 3420,
          mixedVideoLayout: screenSharingActive ? 2 : 1,
        },
      },
      storageConfig: {
        vendor: 1,
        region: 4,
        bucket: credentials.aws.bucketName,
        accessKey: credentials.aws.accessKey,
        secretKey: credentials.aws.secretKey,
        fileNamePrefix: ['agoraVideo'],
      },
    },
  }

  if (screenSharingActive) {
    payload.clientRequest.recordingConfig.transcodingConfig.maxResolutionUid = screenSharingUid.toString()
  }

  const { data } = await httpClient.post(
    `https://api.agora.io/v1/apps/${appid}/cloud_recording/resourceid/${resourceId}/mode/mix/start`,
    payload
  )

  return data
}

export async function updateRecordingLayout({
  cname,
  uid,
  appid,
  resourceId,
  sid,
  screenSharingUid,
  screenSharingActive,
}) {
  const payload = {
    cname,
    uid,
    clientRequest: {
      maxResolutionUid: screenSharingUid.toString(),
      mixedVideoLayout: screenSharingActive ? 2 : 1,
    },
  }

  if (screenSharingActive) {
    payload.clientRequest.maxResolutionUid = screenSharingUid.toString()
  }

  await httpClient.post(
    `https://api.agora.io/v1/apps/${appid}/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/mix/updateLayout`,
    payload
  )
}

export async function stopCloudRecording({
  cname,
  uid,
  appid,
  resourceId,
  sid,
}) {
  const payload = {
    cname,
    uid,
    clientRequest: {},
  }

  const { data } = await httpClient.post(
    `https://api.agora.io/v1/apps/${appid}/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/mix/stop`,
    payload
  )

  return data
}
