var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "ratio img-responsive",
    class: {
      "img-circle": _vm.round,
      "rounded-corners": _vm.rounded,
      frame: _vm.frame
    },
    style: "background-image: url(" + _vm.image + ")"
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }