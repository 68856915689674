var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-layout", [_c("video-tester")], 1),
      _c("v-card-text", { staticClass: "text-center" }, [
        _c("h4", [_vm._v("Venter du på noen?")]),
        _c(
          "div",
          { staticClass: "d-flex justify-center align-center" },
          [
            _c("h6", [
              _vm._v(" Inviter dem til "),
              _c("strong", [_vm._v(_vm._s(_vm.roomUrl))])
            ]),
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "ml-3",
                                attrs: { "x-small": "", icon: "" },
                                on: { click: _vm.copyRoomURL }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("content_copy")])],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Kopier link")])]
            )
          ],
          1
        )
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-auto",
              attrs: { color: "info" },
              on: { click: _vm.enterRoom }
            },
            [_vm._v(" Start nettundervisning ")]
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "success" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function($event) {
                            _vm.snackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Lukk ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_c("v-icon", [_vm._v("done")]), _vm._v(" Link kopiert ")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }