<template>
  <div class="room-container">
    <span
      v-if="sessionWithoutVideoChatActive && $vuetify.breakpoint.smAndUp"
      class="drawing-board-name"
    >
      Tegnebrettmodus
    </span>

    <v-btn
      v-if="sessionWithoutVideoChatActive"
      class="start-video-text"
      :fab="$vuetify.breakpoint.xsOnly"
      @click="setWelcomeDialogVisibility(true)"
      :color="$vuetify.breakpoint.xsOnly && 'white'"
    >
      <span v-if="$vuetify.breakpoint.smAndUp">
        Start video
      </span>
      <v-icon v-if="$vuetify.breakpoint.xsOnly">
        videocam
      </v-icon>
    </v-btn>

    <!-- Loading video chat-->
    <div
      v-if="loadingVideoChat"
      class="video-chat-loading-container"
    >
      <v-skeleton-loader
        type="image"
        transition="fade-transition"
        height="250px"
      />
    </div>

    <!-- Video Chat -->
    <div
      v-if="$store.state.videoChat.active"
      class="video-chat-container-outer"
    >
      <div class="video-chat-container-inner">
        <video-chat
          v-show="!loadingVideoChat"
          @errorStartingRecording="errorSnackbar = true"
        />
      </div>
    </div>

    <div v-if="shouldDisplayWhiteboard">
      <whiteboard />
    </div>

    <v-dialog
      v-model="welcomeDialogVisible"
      width="60%"
      persistent
    >
      <welcome-dialog
        v-if="welcomeDialogVisible"
        @startVideoChat="startVideoChat"
      />
    </v-dialog>

    <v-snackbar
      v-model="errorSnackbar"
      color="error"
      :style="{ zIndex: 10000 }"
    >
      <v-icon>error_outline</v-icon>
      Klarte ikke starte opptak. Kontakt support hvis feilen vedvarer.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          Lukk
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk';
import { mapMutations } from 'vuex';
import random from 'lodash/random';

import VideoChat from '../components/VideoChat.vue';
import WelcomeDialog from '../components/WelcomeDialog.vue';
import Whiteboard from '../components/Whiteboard.vue';

export default {
  name: 'Room',
  components: {
    VideoChat,
    WelcomeDialog,
    Whiteboard,
  },
  data() {
    return {
      users: null,
      errorSnackbar: false,
    };
  },
  computed: {
    sessionWithoutVideoChatActive() {
      return (
        this.$store.state.session.active &&
        !this.$store.state.videoChat.active &&
        !this.$store.state.ui.loadingVideoChat
      );
    },
    device() {
      return this.$store.state.device;
    },
    loadingVideoChat() {
      return this.$store.state.ui.loadingVideoChat;
    },
    sessionActive() {
      return this.$store.state.session.active;
    },
    userDrawer() {
      return this.$store.state.ui.userDrawer;
    },
    welcomeDialogVisible: {
      set(visibility) {
        this.setWelcomeDialogVisibility(visibility);
      },
      get() {
        return this.$store.state.ui.welcomeDialogVisible;
      },
    },
    shouldDisplayWhiteboard() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return this.sessionActive && !this.videoChatActive;
      }

      return this.sessionActive;
    },
    alertObject() {
      const alertObject = {
        headline: 'Videosamtaler er ikke støttet i denne nettleseren',
        message: 'Prøv en annen nettleser',
        link: '',
      };

      if (this.device.isMobile) {
        if (this.$store.getters.isNonSafariOniOS) {
          if (this.device.isChrome) alertObject.headline = 'Videosamtaler er ikke støttet i Chrome på iOS';
          else alertObject.headline = 'Videosamtaler er ikke støttet i denne nettleseren på iOS';
          alertObject.message = 'Videosamtaler er kun støttet i Safari på iPhone og iPad. Åpne linken i Safari for å starte videosamtale. Du kan fremdeles bruke tavlen til å tegne på.';
        }
      }

      return alertObject;
    },
  },
  beforeMount() {
    // If the room name contains unsupported characters (., /, Æ, Ø, Å, etc), replace them with valid chars.
    const path = this.$route.path.split('/')[1];

    const sanitizedPath = path
      .replace(new RegExp('æ', 'g'), 'ae')
      .replace(new RegExp('ø', 'g'), 'o')
      .replace(new RegExp('å', 'g'), 'aa')
      .replace(new RegExp(/[$-/:-?{-~!"^_`\[\]]/, 'g'), ''); // eslint-disable-line

    if (sanitizedPath !== path) {
      this.$router.push('/' + sanitizedPath);
    }
  },
  async mounted() {
    window.analytics.page(this.name);

    if (!this.$store.state.device.hasTouch || this.$vuetify.breakpoint.smAndUp) {
      this.setWelcomeDialogVisibility(true);
    }
    else {
      this.setSessionActive(true);
    }

    await this.$db.ref('/boards/' + this.$route.params.roomID + '/users').on('value', (snapshot, err) => {
      if (err) {
        this.$handleError(err);
      }
      else {
        this.users = snapshot.val();
      }
    });

    const screenSharingRef = this.$db.ref('/boards/' + this.$route.params.roomID + '/screenSharing');
    await this.$db.ref('/boards/' + this.$route.params.roomID + '/screenSharing').on('value', (snapshot) => {
      const screenSharing = snapshot.val();

      if (!screenSharing?.screenId) {
        screenSharingRef.set({
          screenId: random(1, 9999999),
        });
      }
    });

    await screenSharingRef.onDisconnect().remove();
  },
  beforeDestroy() {
    this.setSessionActive(false);
  },
  methods: {
    ...mapMutations([
      'displayAlert',
      'setWelcomeDialogVisibility',
      'setSessionActive',
      'setLoadingVideoChat',
      'setVideoChatActive',
    ]),
    startVideoChat() {
      if (AgoraRTC.checkSystemRequirements()) {
        this.setWelcomeDialogVisibility(false);
        this.setLoadingVideoChat(true);
        this.setSessionActive(true);
        this.setVideoChatActive(true);
      }
      else {
        this.displayAlert(this.alertObject);
        this.setWelcomeDialogVisibility(false);
      }
    },
  },
};
</script>

<style scoped lang="sass">
.room-container
  padding: 5px
  background-color: #FFF

.drawing-board-name
  right: 25px
  top: 0
  position: fixed
  z-index: 1
  font-size: 12px
  color: grey

.start-video-text
  right: 10px
  top: 20px
  position: fixed
  z-index: 1
  font-size: 12px
  color: grey

.video-chat-loading-container
  position: fixed
  top: 12px
  right: 60px
  z-index: 10
  height: 250px
  width: 350px

@media (max-width: 960px)
  .video-chat-loading-container
    top: 60px

@media (max-width: 600px)
  .video-chat-loading-container
    right: calc(50% - 175px)

  .start-video-text
    bottom: 68px
    top: auto

.video-chat-container-outer
  position: fixed
  z-index: 1

.video-chat-container-inner
  position: fixed
  z-index: 10
  right: 60px

.get-started h2
  font-weight: bold
  text-align: left !important
</style>
