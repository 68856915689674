<template>
  <div
    class="ratio img-responsive"
    :class="{ 'img-circle': round, 'rounded-corners': rounded, frame: frame }"
    :style="'background-image: url(' + image + ')'"
  />
</template>

<script>
export default {
  props: ['photoURL', 'round', 'rounded', 'frame'],
  computed: {
    image: function() {
      return this.photoURL ? this.photoURL : 'assets/nophoto.jpg'
    },
  },
}
</script>

<style scoped>
.caption div {
  box-shadow: 0 0 5px #c8c8c8;
  transition: all 0.3s ease 0s;
}

.frame {
  padding: 4px;
  border: 1px solid #989898;
  box-shadow: 0 0 5px #c8c8c8;
}

.ratio {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.img-circle {
  border-radius: 50%;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
}

.rounded-corners {
  border-radius: 1%;
}
</style>
