<template>
  <div>
    <v-layout column>
      <v-flex v-if="whiteboardParams.showTextControls">
        <v-toolbar
          dense
          flat
          class="text-toolbar"
          :class="{'mt-7': $vuetify.breakpoint.mdAndDown, 'mt-0': $vuetify.breakpoint.mdAndUp}"
        >
          <v-overflow-btn
            v-model="whiteboardParams.fontFamily"
            :items="whiteboardParams.fontTypes"
            label="Font"
            hide-details
            class="pa-0"
          />

          <v-divider vertical />

          <v-overflow-btn
            v-model="whiteboardParams.textSize"
            :items="whiteboardParams.textSizes"
            editable
            label="Størrelse"
            hide-details
            class="pa-0"
            overflow
          />
        </v-toolbar>
      </v-flex>

      <v-flex>
        <canvas id="whiteboard" />
      </v-flex>
    </v-layout>

    <v-layout
      v-if="!helperDrawerActive && !userDrawerActive"
      class="whiteboard-controls-container"
      type="flex"
      justify="center"
    >
      <whiteboard-controls
        :whiteboard-params="whiteboardParams"
        :canvas="canvas"
        @setLineWidth="handleSetLineWidth"
        @setActiveTool="handleSetActiveTool"
        @setLineColor="handleSetLineColor"
        @setPencilLineColor="handleSetPencilLineColor"
        @setPausePanning="handleSetPausePanning"
        @setIsDrawingMode="handleSetIsDrawingMode"
        @clearCanvas="handleClearCanvas"
        @zoomToPoint="handleZoomToPoint"
      />
    </v-layout>
  </div>
</template>

<script>
import { fabric } from 'fabric-with-gestures';

import WhiteboardControls from '../components/WhiteboardControls';
import throttle from 'lodash/throttle';

export default {
  name: 'Whiteboard',
  components: {
    WhiteboardControls,
  },
  data() {
    return {
      touching: false,
      selectedPath: null,
      whiteboardParams: {
        activeTool: 'moveBoard',
        boardID: 0,
        canvasHeight: 0,
        canvasWidth: 0,
        colorItems: [
          'rgb(220,7,0)',
          'rgb(55,255,55)',
          'rgb(0,121,255)',
          'rgb(154,0,26)',
          'rgb(31,168,0)',
          'rgb(0,52,160)',
          'rgb(255,233,0)',
          'rgb(255,0,183)',
          'rgb(0, 0, 0)',
        ],
        currentX: 0,
        currentY: 0,
        fontFamily: 'Arial',
        fontTypes: [{ text: 'Arial' }, { text: 'Times New Roman' }, { text: 'Comic Sans MS' }],
        lineColor: '#000000',
        lineWidth: 3,
        pausePanning: false,
        pencilLineColor: '#000000',
        showTextControls: false,
        textColor: '#000000',
        textSize: '18',
        textSizes: [
          { text: '8' },
          { text: '9' },
          { text: '10' },
          { text: '11' },
          { text: '12' },
          { text: '14' },
          { text: '18' },
          { text: '24' },
          { text: '30' },
          { text: '36' },
          { text: '48' },
          { text: '60' },
          { text: '72' },
          { text: '96' },
        ],
        userID: '',
      },
      canvas: null,
      fbRefs: {
        usersRef: null,
        objectsRef: null,
        viewportRef: null,
      },
    };
  },
  computed: {
    helperDrawerActive() {
      return this.$store.state.ui.helpDrawer;
    },
    userDrawerActive() {
      return this.$store.state.ui.userDrawer;
    },
  },
  watch: {
    'whiteboardParams.lineColor': function(newValue) {
      const line = this.canvas.getActiveObject();

      if (line) {
        line.set('stroke', newValue);

        if (line.type === 'image') {
          const filter = new fabric.Image.filters.BlendColor({
            color: newValue,
            mode: 'tint',
          });
          line.filters.push(filter);
          line.applyFilters();
        }
        this.updateObject(line.toJSON(), line.ID);
      }
      else {
        this.canvas.freeDrawingBrush.color = newValue;
      }
    },
    'whiteboardParams.lineWidth': function(newValue) {
      this.canvas.freeDrawingBrush.width = newValue;
    },
    'whiteboardParams.fontFamily': function(newValue) {
      const text = this.canvas.getActiveObject();
      text.set('fontFamily', newValue);

      if (text.ID) {
        this.updateObject(text.toJSON(), text.ID);
      }
    },
    'whiteboardParams.textColor': function(newValue) {
      const text = this.canvas.getActiveObject();
      text.setColor(newValue);

      if (text.ID) {
        this.updateObject(text.toJSON(), text.ID);
      }
    },
    'whiteboardParams.textSize': function(newValue) {
      const text = this.canvas.getActiveObject();
      text.set('fontSize', newValue);

      if (text.ID) {
        this.updateObject(text.toJSON(), text.ID);
      }
    },
  },
  beforeDestroy() {
    this.fbRefs.objectsRef.once('value').then((snap) => {
      snap.forEach((child) => {
        if (child.val().uid === this.whiteboardParams.userID && child.val().isViewport) {
          child.ref.remove();
        }
      });
      this.fbRefs.usersRef.child(this.whiteboardParams.userID).remove();
    });
  },
  mounted() {
    console.info('mounted Whiteboard');
    this.whiteboardParams.boardID = this.$route.params.roomID;

    // FIXME: window.analytics.user is not a function
    this.whiteboardParams.userID = window.analytics.user().anonymousId();

    // Set Firebase Refs
    const rootPath = `boards/${this.whiteboardParams.boardID}`;

    this.fbRefs.usersRef = this.$db.ref(rootPath + '/users');
    this.fbRefs.objectsRef = this.$db.ref(rootPath + '/board/objects');

    this.canvasHeight = window.innerHeight - 64;
    this.canvasWidth = window.innerWidth;

    this.createCanvas();

    this.$db.ref('.info/connected').on('value', (snapshot) => {
      if (snapshot.val() === true) {
        this.fbRefs.usersRef
          .child(this.whiteboardParams.userID)
          .once('value')
          .then((s) => {
            // If the current user isn't already added as a user to the board
            if (!s.val()) {
              this.fbRefs.usersRef
                .child(this.whiteboardParams.userID)
                .set({
                  screenHeight: window.innerHeight,
                  screenWidth: window.innerWidth,
                  uid: this.whiteboardParams.userID,
                })
                .then(() => {
                  this.fbRefs.usersRef.child(this.whiteboardParams.userID).onDisconnect().remove();
                  this.fbRefs.objectsRef.once('value').then((snap) => {
                    snap.forEach((child) => {
                      if (child.val().uid === this.whiteboardParams.userID && child.val().isViewport) {
                        child.ref.onDisconnect().remove();
                      }
                    });
                  });
                });
            }
            else {
              // Else (the current user is connecting with a second device to the same board)
              this.whiteboardParams.userID = this.whiteboardParams.userID + '-2';

              this.fbRefs.usersRef
                .child(this.whiteboardParams.userID)
                .set({
                  screenHeight: window.innerHeight,
                  screenWidth: window.innerWidth,
                  uid: this.whiteboardParams.userID,
                })
                .then(() => {
                  this.fbRefs.usersRef.child(this.whiteboardParams.userID).onDisconnect().remove();
                  this.fbRefs.objectsRef.once('value').then((snap) => {
                    snap.forEach((child) => {
                      if (child.val().uid === this.whiteboardParams.userID && child.val().isViewport) {
                        child.ref.onDisconnect().remove();
                      }
                    });
                  });
                });
            }
            this.connectViewportListener();
          });
      }
    });

    this.fbRefs.objectsRef.on('child_added', (canvasObject) => {
      if (canvasObject.exists()) {
        const key = canvasObject.key;
        const canvasObjectValue = canvasObject.val();

        fabric.util.enlivenObjects(
          [canvasObjectValue],
          (objects) => {
            objects.forEach((o) => {
              // If own viewport-object
              if (o.uid === this.whiteboardParams.userID && o.isViewport) {
                return;
              }
              o.ID = key;
              this.canvas.add(o);
            });
          },
          null,
          null,
        );
      }
    });

    this.fbRefs.objectsRef.on('child_changed', (canvasObjectSnapshot) => {
      if (canvasObjectSnapshot.exists()) {
        const key = canvasObjectSnapshot.key;
        const canvasObjectValue = canvasObjectSnapshot.val();

        this.deleteObjectOnId(key);

        fabric.util.enlivenObjects(
          [canvasObjectValue],
          (objects) => {
            objects.forEach((o) => {
              if (o.uid === this.whiteboardParams.userID && o.isViewport) {
                return;
              }

              o.ID = key;
              this.canvas.add(o);

              if (!o.isViewport) {
                this.canvas.setActiveObject(o);
              }
            });
          },
          null,
          null,
        );
      }
    });

    this.fbRefs.objectsRef.on('child_removed', (canvasObject) => {
      this.deleteObjectOnId(canvasObject.key);
    });

    document.addEventListener('keydown', (event) => {
      const obj = this.canvas.getActiveObject();

      if (
        obj &&
        obj.type !== 'textbox' &&
        this.whiteboardParams.activeTool !== 'addText' &&
        event.key === 'Backspace'
      ) {
        this.fbRefs.objectsRef.child('/' + obj.ID).remove();
      }
    });

    // When the user resizes the window, wait one second, then update the canvas size.
    let resizeTimer;

    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);

      resizeTimer = setTimeout(() => {
        this.canvasHeight = window.innerHeight;
        this.canvasWidth = window.innerWidth;

        this.canvas.setWidth(this.canvasWidth);
        this.canvas.setHeight(this.canvasHeight);

        this.fbRefs.usersRef.child(this.whiteboardParams.userID).update({
          screenHeight: window.innerHeight,
          screenWidth: window.innerWidth,
          uid: this.whiteboardParams.userID,
        });
      }, 500);
    });
  },
  methods: {
    addObject(obj) {
      obj.uid = this.whiteboardParams.userID;
      this.fbRefs.objectsRef.push(obj);
    },
    addViewport(rect) {
      rect.uid = this.whiteboardParams.userID;
      rect.isViewport = true;
      rect.selectable = false;
      this.fbRefs.objectsRef.push(rect);
    },
    addViewportLabel(label) {
      label.uid = this.whiteboardParams.userID;
      label.isViewport = true;
      label.isLabel = true;
      label.selectable = false;
      this.fbRefs.objectsRef.push(label);
    },
    addText(x, y) {
      this.whiteboardParams.textSize = 18;
      this.whiteboardParams.fontFamily = 'Arial';
      this.whiteboardParams.textColor = '#000000';
      this.canvas.isDrawingMode = false;

      const newText = new fabric.Textbox('', {
        fontFamily: 'Arial',
        fontSize: 18,
        left: x,
        top: y,
        width: 300,
      });

      this.canvas.add(newText);
      this.canvas.setActiveObject(newText);

      newText.enterEditing();
    },
    createCanvas() {
      this.canvas = new fabric.Canvas('whiteboard', {
        isDrawingMode: false,
        selection: false,
      });

      fabric.Object.prototype.transparentCorners = false;

      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);

      this.canvas.setWidth(this.canvasWidth);
      this.canvas.setHeight(this.canvasHeight);

      this.canvas.freeDrawingBrush.width = this.whiteboardParams.lineWidth;

      this.canvas.defaultCursor = 'move';
      this.canvas.hoverCursor = 'move';

      this.canvas
        .on('mouse:move', (event) => {
          // @stylePath = Styles error handler
          const stylePath = event.e && event.e.path;

          // Setting the cursor to a round point when in drawing mode.
          if (this.canvas.isDrawingMode && stylePath && event.e.path[0].tagName === 'CANVAS') {
            const style = event.e.path[0].getAttribute('style');
            const regex = /cursor:.*/;
            const size = this.canvas.freeDrawingBrush.width;
            const url = `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='${size}' width='${size}'%3E%3Ccircle cx='${size / 2
              }' cy='${size / 2}' r='${size / 2}' stroke='black' stroke-width='1' fill='gray' /%3E%3C/svg%3E"`;
            const newStyle = style.replace(regex, `cursor: url(${url}) ${size / 2} ${size / 2}, auto !important;`);
            event.e.path[0].setAttribute('style', newStyle);
          }
        })
        .on('mouse:over', (event) => {
          if (event.target && (this.whiteboardParams.activeTool === 'superEraser' || (event.target.selectable && this.whiteboardParams.activeTool === 'selectElement'))) {
            this.selectedPath = event.target;

            if (!this.touching) {
              this.selectedPath && (this.selectedPath.opacity = 0.3);
              this.canvas.renderAll();
            }
            else {
              this.fbRefs.objectsRef.child('/' + this.selectedPath.ID).remove();
            }
          }
        })
        .on('mouse:out', () => {
          if (
            this.whiteboardParams.activeTool === 'superEraser' ||
            this.whiteboardParams.activeTool === 'selectElement'
          ) {
            this.selectedPath && (this.selectedPath.opacity = 1);
            this.selectedPath = null;
            this.canvas.renderAll();
          }
        })
        .on('mouse:down', (obj) => {
          this.touching = true;

          if (this.whiteboardParams.activeTool === 'superEraser' && this.selectedPath) {
            this.fbRefs.objectsRef.child('/' + this.selectedPath.ID).remove();
          }
          else this.selectedPath = null;

          // Adds link to the entire object. If there are multiple links in the object, only the first one is added.
          if (obj && obj.target && obj.target.text) {
            const wordList = obj.target.text.split(" ");
            if (!String.linkify) {
              String.prototype.linkify = function() {

                const urlPattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

                if (urlPattern.test(wordList)) {
                  let url = wordList.filter(word => urlPattern.test(word))[0];
                  const httpCheck = url.substring(0, 4);
                  if (httpCheck !== "http") {
                    url = "http://" + url;
                  }

                  if (window.confirm('Vil du forlate tavlen og følge denne lenken: ' + url + '?')) {
                    window.open(url, "_blank");
                  }
                }
              };
            }
            obj.target.text.linkify();
          }
        })
        .on('object:added', (event) => {
          if (event.target.isViewport) event.target.sendToBack();
        })
        .on('object:selected', () => {
          this.whiteboardParams.pausePanning = true;
        })
        .on('selection:updated', (e) => {
          if (e.target.type === 'textbox') {
            this.whiteboardParams.activeTool = 'addText';
          }
          else {
            this.whiteboardParams.activeTool = 'selectElement';
          }
        })
        .on('selection:created', (e) => {
          if (e.target.type === 'textbox') {
            this.whiteboardParams.textSize = e.target.fontSize;
            this.whiteboardParams.fontFamily = e.target.fontFamily;
            this.whiteboardParams.textColor = e.target.fill;
            this.whiteboardParams.showTextControls = true;
            this.whiteboardParams.activeTool = 'addText';
          }
          else {
            this.whiteboardParams.activeTool = 'selectElement';
          }
        })
        .on('selection:cleared', () => {
          this.whiteboardParams.pausePanning = false;
          this.whiteboardParams.showTextControls = false;
        })
        .on('touch:drag', (e) => {
          if (
            !this.canvas.isDrawingMode &&
            !this.whiteboardParams.pausePanning &&
            ((e.e.layerX && e.e.layerY) || e.e.touches)
          ) {
            const newX = e.e.layerX || e.e.touches[0].screenX;
            const newY = e.e.layerY || e.e.touches[0].screenY;

            this.whiteboardParams.currentX = newX;
            this.whiteboardParams.currentY = newY;
            this.whiteboardParams.xChange = this.whiteboardParams.currentX - this.whiteboardParams.lastX;
            this.whiteboardParams.yChange = this.whiteboardParams.currentY - this.whiteboardParams.lastY;

            if (
              Math.abs(this.whiteboardParams.currentX - this.whiteboardParams.lastX) <= 50 &&
              Math.abs(this.whiteboardParams.currentY - this.whiteboardParams.lastY) <= 50
            ) {
              const delta = new fabric.Point(this.whiteboardParams.xChange, this.whiteboardParams.yChange);
              this.canvas.relativePan(delta);
            }

            this.whiteboardParams.lastX = newX;
            this.whiteboardParams.lastY = newY;
          }
        })
        .on('object:modified', (e) => {
          const objectID = e.target.ID;

          if (objectID && objectID !== 'undefined') {
            e.target.opacity = 1;
            this.updateObject(e.target.toJSON(), objectID);
          }
          else {
            this.canvas.remove(e.target);
            this.addObject(e.target.toJSON());
          }
        })
        .on('object:created', (e) => {
          this.addObject(e.target.toJSON());
        })
        .on('object:removed', () => {
          this.selectedPath = null;

          this.canvas.renderAll();
        })
        .on('path:created', (e) => {
          this.canvas.remove(e.path);
          e.path.fill = '';

          if (this.whiteboardParams.activeTool === 'eraser') {
            const { left, top } = e.path;
            const newPath = Object.assign(e.path);
            newPath.globalCompositeOperation = 'destination-out';
            const objects = this.canvas.getObjects().filter((obj) => {
              return obj.intersectsWithObject(newPath);
            });

            objects.map((path) => {
              this.fbRefs.objectsRef.child('/' + path.ID).remove();
              newPath.left = left;
              newPath.top = top;
              const group = new fabric.Group([Object.assign(path), Object.assign(newPath)]);

              const cropLeftOffset = path.left - newPath.left;
              const cropTopOffset = path.top - newPath.top;
              const cropWidth = path.type === 'image' ? path.width : path.width + path.strokeWidth;
              const cropHeight = path.type === 'image' ? path.height : path.height + path.strokeWidth;

              const newData = group.toDataURL({
                left: cropLeftOffset < 0 ? 0 : cropLeftOffset,
                top: cropTopOffset < 0 ? 0 : cropTopOffset,
                width: cropWidth,
                height: cropHeight,
              });

              fabric.Image.fromURL(newData, (fabricImage) => {
                fabricImage.set({
                  left: group.left + (cropLeftOffset < 0 ? 0 : cropLeftOffset),
                  top: group.top + (cropTopOffset < 0 ? 0 : cropTopOffset),
                });

                if (!path.cropped) {
                  fabricImage.cropped = true;
                }

                this.addObject(fabricImage.toJSON());
              });
            });
          }
          else {
            this.addObject(e.path.toJSON());
          }
        })
        .on('mouse:up', (e) => {
          this.touching = false;
          this.selectedPath = null;

          // If text tool is active and no object is selected => Add new text
          if (
            this.whiteboardParams.activeTool === 'addText' &&
            ((e.target && e.target.type !== 'textbox') || !e.target)
          ) {
            this.addText(e.absolutePointer.x, e.absolutePointer.y);
          }
        })
        .on('after:render', throttle(() => {
          const tlX = this.canvas.vptCoords.tl.x;
          const tlY = this.canvas.vptCoords.tl.y;
          const brX = this.canvas.vptCoords.br.x;
          const brY = this.canvas.vptCoords.br.y;
          this.fbRefs.usersRef.child(this.whiteboardParams.userID).once('value', (snap) => {
            if (snap.val()) {
              snap.ref.child('viewport').set({
                tlX,
                tlY,
                brX,
                brY,
              });
            }
          });
        }, 1000),
        );
    },
    connectViewportListener() {
      if (!this.fbRefs.viewportRef) {
        this.fbRefs.viewportRef = this.fbRefs.usersRef.child(this.whiteboardParams.userID)
          .child('viewport');

        this.fbRefs.viewportRef
          .on('value', (s) => {
            if (!s.val()) {
              return;
            }
            const { brX, brY, tlX, tlY } = s.val();

            const rectParams = {
              width: brX - tlX,
              height: brY - tlY,
              left: tlX,
              top: tlY,
              stroke: 'rgba(0, 0, 0)',
              strokeDashArray: [5, 5],
              fill: 'rgba(0, 0, 0, 0)',
              selectable: false,
              evented: false,
            };

            const textParams = {
              fontFamily: 'Arial',
              fontSize: 14,
              left: tlX,
              top: tlY + 1,
              selectable: false,
              evented: false,
            };

            if (!this.viewportRect) {
              this.viewportRect = new fabric.Rect(rectParams);

              const label = new fabric.Text('Skjermen til den andre brukeren', textParams);

              this.addViewport(this.viewportRect.toJSON());
              this.addViewportLabel(label.toJSON());
            }
            else {
              this.fbRefs.objectsRef.once('value').then((snap) => {
                snap.forEach((child) => {
                  const boardObject = child.val();
                  if (boardObject.uid === this.whiteboardParams.userID && boardObject.isViewport) {
                    const updateData = boardObject.isLabel
                      ? {
                        ...textParams,
                        isViewport: true,
                        isLabel: true,
                      }
                      : {
                        ...rectParams,
                        isViewport: true,
                      };
                    child.ref.update(updateData);
                  }
                });
                this.canvas.renderAll();
              });
            }
          });
      }
    },
    deleteObjectOnId(ID) {
      for (let i = 0; i < this.canvas._objects.length; i++) {
        if (this.canvas._objects[i].ID === ID) {
          this.canvas.remove(this.canvas._objects[i]);
          break;
        }
      }

      setTimeout(() => {
        this.canvas.renderAll();
      }, 100);
    },
    updateObject(obj, ID) {
      obj.uid = this.whiteboardParams.userID;
      this.fbRefs.objectsRef.child('/' + ID).set(obj);
    },
    handleSetLineWidth(width) {
      this.whiteboardParams.lineWidth = width;
    },
    handleSetActiveTool(toolId) {
      this.whiteboardParams.activeTool = toolId;
    },
    handleSetLineColor(lineColor) {
      this.whiteboardParams.lineColor = lineColor;
    },
    handleSetPencilLineColor(pencilLineColor) {
      this.whiteboardParams.pencilLineColor = pencilLineColor;
    },
    handleSetPausePanning(pausePanning) {
      this.whiteboardParams.pausePanning = pausePanning;
    },
    handleSetIsDrawingMode(isDrawingMode) {
      this.canvas.isDrawingMode = isDrawingMode;
    },
    handleClearCanvas() {
      this.fbRefs.objectsRef.once('value').then((snap) => {
        snap.forEach((child) => {
          if (!child.val().isViewport) {
            child.ref.remove();
          }
        });
      });
    },
    handleZoomToPoint({ point, scale }) {
      this.canvas.isDrawingMode = false;
      this.whiteboardParams.activeTool = 'moveBoard';

      this.canvas.zoomToPoint(point, scale);
    },
  },
};
</script>

<style scoped lang="scss">
.whiteboard-controls-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.text-toolbar {
  position: absolute;
  width: 60% !important;
  z-index: 10;
}

#whiteboard {
  background-color: white;
}
</style>
