<template>
  <v-layout>
    <v-toolbar color="#fff" height="50px">
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <div>
                <v-icon
                  :style="{
                    color: whiteboardParams.pencilLineColor,
                  }"
                >
                  palette
                </v-icon>
                <p>
                  <strong>Farger</strong>
                </p>
              </div>
            </v-btn>
          </template>

          <v-list style="width: 165px; flex-flow: unset; flex-wrap: wrap;">
            <div
              v-for="(item, index) in whiteboardParams.colorItems"
              :key="index"
              flex
              justify-center
              class="list-item"
              style="width: auto; padding: 10px; box-sizing: content-box;"
              @click="activateTool('drawing', item)"
            >
              <span
                :style="{
                  display: 'block',
                  width: '35px',
                  height: '35px',
                  backgroundColor: item,
                }"
              />
            </div>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <div>
                <v-icon>
                  line_weight
                </v-icon>
                <p>
                  <strong>Tykkelse</strong>
                </p>
              </div>
            </v-btn>
          </template>

          <v-col class="slider-wrapper">
            <v-slider
              v-model="lineWidthModel"
              min="2"
              max="80"
              append-icon="add"
              prepend-icon="remove"
              hide-details
            />
          </v-col>
        </v-menu>

        <v-btn
          icon
          :color="whiteboardParams.activeTool === 'drawing' ? '#009bff' : ''"
          @click="activateTool('drawing')"
        >
          <div>
            <v-icon>
              create
            </v-icon>
            <p>
              <strong>Penn</strong>
            </p>
          </div>
        </v-btn>

        <v-btn
          icon
          :color="
            whiteboardParams.activeTool === 'superEraser' ? '#009bff' : ''
          "
          @click="activateTool('superEraser')"
        >
          <div>
            <v-icon style="transform: rotate(-180deg);">
              edit
            </v-icon>
            <p>
              <strong>Slett</strong>
            </p>
          </div>
        </v-btn>

        <v-btn
          icon
          :color="
            whiteboardParams.activeTool === 'selectElement' ? '#009bff' : ''
          "
          @click="activateTool('selectElement')"
        >
          <div>
            <v-icon>
              touch_app
            </v-icon>
            <p>
              <strong>Velge</strong>
            </p>
          </div>
        </v-btn>

        <v-btn
          icon
          :color="whiteboardParams.activeTool === 'moveBoard' ? '#009bff' : ''"
          @click="activateTool('moveBoard')"
        >
          <div>
            <v-icon>
              open_with
            </v-icon>
            <p>
              <strong>Flytt</strong>
            </p>
          </div>
        </v-btn>

        <v-btn
          icon
          :color="whiteboardParams.activeTool === 'addText' ? '#009bff' : ''"
          @click="activateTool('addText')"
        >
          <div>
            <v-icon>
              title
            </v-icon>
            <p>
              <strong>Tekst</strong>
            </p>
          </div>
        </v-btn>

        <v-btn icon :disabled="!objectsToDelete" @click="clearCanvas">
          <div>
            <v-icon>
              delete
            </v-icon>
            <p>
              <strong>Slett alt</strong>
            </p>
          </div>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-btn
      fab
      fixed
      bottom
      small
      color="transparent"
      :style="{
        bottom: '75px',
        left: $vuetify.breakpoint.smAndUp ? '30px' : '50px',
      }"
      @click="zoomIn"
    >
      <v-icon color="black">
        add
      </v-icon>
    </v-btn>

    <v-btn
      fab
      fixed
      bottom
      small
      color="transparent"
      :style="{
        bottom: '75px',
        left: $vuetify.breakpoint.smAndUp ? '-20px' : '0',
      }"
      @click="zoomOut"
    >
      <v-icon color="black">
        remove
      </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import { fabric } from 'fabric-with-gestures';

export default {
  name: 'WhiteboardControls',
  props: ['whiteboardParams', 'canvas'],
  data() {
    return {
      colorPaletteVisible: false,
      objectsToDelete: false,
    };
  },
  computed: {
    lineWidthModel: {
      set(value) {
        this.$emit('setLineWidth', value);
      },
      get() {
        return this.whiteboardParams.lineWidth;
      },
    },
  },
  watch: {
    'canvas._objects'() {
      // Should be inactive if only the
      this.objectsToDelete = this.canvas._objects.length > 2;
    },
  },
  mounted() {
    try {
      fabric.Object.prototype.selectable = false;
    }
    catch (e) {
      this.$handleError(e);
    }
  },
  methods: {
    activateTool(toolID, color) {
      try {
        this.canvas.discardActiveObject();

        this.$emit('setActiveTool', toolID);

        color = toolID === 'eraser' ? 'white' : color;

        if (color) {
          this.$emit('setLineColor', color);
          this.$emit('setPencilLineColor', color);
        }

        if (
          toolID === 'moveBoard' ||
          toolID === 'eraser' ||
          toolID === 'superEraser' ||
          toolID === 'addText'
        ) {
          this.canvas.discardActiveObject();
          fabric.Object.prototype.selectable = false;
          this.canvas.renderAll();

          this.$emit('setPausePanning', toolID === 'superEraser');
        }
        else if (
          !fabric.Object.prototype.selectable &&
          toolID === 'selectElement' &&
          toolID !== 'addText' &&
          toolID !== 'moveBoard' &&
          toolID !== 'superEraser'
        ) {
          fabric.Object.prototype.selectable = true;
          this.canvas.renderAll();
        }

        this.setCursorStyles(toolID);

        this.$emit(
          'setIsDrawingMode',
          this.whiteboardParams.activeTool === 'drawing' ||
          this.whiteboardParams.activeTool === 'eraser',
        );
      }
      catch (e) {
        this.$handleError(e);
      }
    },
    setCursorStyles(toolID) {
      switch (toolID) {
        case 'superEraser':
          this.canvas.defaultCursor = 'default';
          this.canvas.hoverCursor = 'url("/clear-black-18dp.svg"), auto';
          break;
        case 'selectElement':
          this.canvas.defaultCursor = 'default';
          this.canvas.hoverCursor = 'pointer';
          break;
        case 'moveBoard':
          this.canvas.defaultCursor = 'move';
          this.canvas.hoverCursor = 'move';
          break;
        case 'addText':
          this.canvas.defaultCursor = 'text';
          this.canvas.hoverCursor = 'text';
          break;
        default:
          this.canvas.defaultCursor = 'default';
          this.canvas.hoverCursor = 'default';
          break;
      }
    },
    clearCanvas() {
      try {
        this.$emit('clearCanvas');
        this.objectsToDelete = false;
      }
      catch (e) {
        this.$handleError(e);
      }
    },
    zoomOut() {
      try {
        const y = Math.round(this.whiteboardParams.canvasHeight / 2);
        const x = Math.round(this.whiteboardParams.canvasWidth / 2);
        const point = new fabric.Point(x, y);
        const scale = this.canvas.getZoom() * 0.8;

        this.$emit('zoomToPoint', { point, scale });
      }
      catch (e) {
        this.$handleError(e);
      }
    },
    zoomIn() {
      try {
        const y = Math.round(this.whiteboardParams.canvasHeight / 2);
        const x = Math.round(this.whiteboardParams.canvasWidth / 2);
        const point = new fabric.Point(x, y);
        const scale = this.canvas.getZoom() * 1.2;

        this.$emit('zoomToPoint', { point, scale });
      }
      catch (e) {
        this.$handleError(e);
      }
    },
  },
};
</script>

<style scoped>
button {
  margin: 0 3%;
  padding-top: 2px;
  height: 48px;
  min-width: 65px;
}

button p {
  font-size: 10px;
  margin: 0;
}

.v-btn--fab {
  margin-right: 0;
  width: 40px !important;
  height: 40px !important;
  min-width: 0 !important;
}

.v-toolbar__items {
  width: 100%;
  max-width: 800px;
  justify-content: space-evenly;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .v-toolbar__items {
    overflow-x: auto;
    justify-content: flex-start;
  }
}

.v-toolbar__items >>> .v-btn__content {
  margin-top: -10px;
}

.v-btn--floating {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0), 0 6px 10px 0 rgba(0, 0, 0, 0),
  0 1px 18px 0 rgba(0, 0, 0, 0.08) !important;
}

.theme--light.v-btn--floating {
  height: 30px !important;
  width: 30px !important;
  color: #b4b4b4 !important;
}

.v-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
}

.list-item {
  font-size: 18px;
  width: 200px;
  padding: 3px;
}

.list-item .v-icon {
  font-size: 27px;
}

.v-menu__content {
  width: auto;
  top: auto !important;
  bottom: 60px !important;
  background: #fff;
}

.slider-wrapper {
  width: 400px;
}
</style>
