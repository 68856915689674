var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "room-container" },
    [
      _vm.sessionWithoutVideoChatActive && _vm.$vuetify.breakpoint.smAndUp
        ? _c("span", { staticClass: "drawing-board-name" }, [
            _vm._v(" Tegnebrettmodus ")
          ])
        : _vm._e(),
      _vm.sessionWithoutVideoChatActive
        ? _c(
            "v-btn",
            {
              staticClass: "start-video-text",
              attrs: {
                fab: _vm.$vuetify.breakpoint.xsOnly,
                color: _vm.$vuetify.breakpoint.xsOnly && "white"
              },
              on: {
                click: function($event) {
                  return _vm.setWelcomeDialogVisibility(true)
                }
              }
            },
            [
              _vm.$vuetify.breakpoint.smAndUp
                ? _c("span", [_vm._v(" Start video ")])
                : _vm._e(),
              _vm.$vuetify.breakpoint.xsOnly
                ? _c("v-icon", [_vm._v(" videocam ")])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.loadingVideoChat
        ? _c(
            "div",
            { staticClass: "video-chat-loading-container" },
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "image",
                  transition: "fade-transition",
                  height: "250px"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.$store.state.videoChat.active
        ? _c("div", { staticClass: "video-chat-container-outer" }, [
            _c(
              "div",
              { staticClass: "video-chat-container-inner" },
              [
                _c("video-chat", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loadingVideoChat,
                      expression: "!loadingVideoChat"
                    }
                  ],
                  on: {
                    errorStartingRecording: function($event) {
                      _vm.errorSnackbar = true
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.shouldDisplayWhiteboard ? _c("div", [_c("whiteboard")], 1) : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "60%", persistent: "" },
          model: {
            value: _vm.welcomeDialogVisible,
            callback: function($$v) {
              _vm.welcomeDialogVisible = $$v
            },
            expression: "welcomeDialogVisible"
          }
        },
        [
          _vm.welcomeDialogVisible
            ? _c("welcome-dialog", {
                on: { startVideoChat: _vm.startVideoChat }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          style: { zIndex: 10000 },
          attrs: { color: "error" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function($event) {
                            _vm.errorSnackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Lukk ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.errorSnackbar,
            callback: function($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar"
          }
        },
        [
          _c("v-icon", [_vm._v("error_outline")]),
          _vm._v(
            " Klarte ikke starte opptak. Kontakt support hvis feilen vedvarer. "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }